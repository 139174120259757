<template>
  <div class="content pointer-event-none" id="px-app" :class="[devEnv ? 'dev' : '']">
    <div class="left-info pointer-event-auto" @click.stop="openUseInfo" v-show="showCard">
      <div class="user-avatar" :style="{border: (skinData && skinData.themeColor) ? '6px solid' + skinData.themeColor : ''}">
        <img :src="studentInfo.avatarUrl" alt="" v-if="studentInfo.avatarUrl">
        <img src="../../assets/img/homepage/empty.png"  class="default-img" alt="" v-else>
      </div>
      <span class="red-tip" v-if="isRemind"></span>
      <div class="myself-panel" :style="{background: (skinData && skinData.themeColor) ? skinData.themeColor : ''}">
        <span class="text">{{studentInfo.englishName}}</span>
      </div>
    </div>
    <div class="container pointer-event-none" v-show="!showChildrens && showCard">
      <networkError class="pointer-event-auto" v-show="isError" :refreshEvent="() => {getCourseCards(false)}"></networkError>
      <div class="course-tip pointer-event-auto" @click="location(topTip.onlineClassId,topTip.tipType)" v-if="!isError && topTip && topTip.tipContents">
        <div v-for="(tipItem,index) in topTip.tipContents" :key="index" class="tip-cont" :class="[tipItem.contentType=='HIGH_LIGHT_TEXT' ? 'highlight':'']">
          <img :src="tipItem.content" alt="" v-if="tipItem.contentType=='IMAGE'" class="img">
          <label v-else>{{tipItem.content}}</label>
        </div>
      </div>
      <div class="nomal-tip left pointer-event-auto" v-show="!isError && currentShowIndex==0" @click="goto(1)">历史课程前往<span>全部课程</span>查看</div>
      <div class="nomal-tip right pointer-event-auto" v-show="!isError && hasAfterNextWeekClass && swiper && swiper.activeIndex + 1 === cardsList.length" @click="goto(2)">未来课程前往<span>全部课程</span>查看</div>
      <div class="cont pointer-event-none" v-show="!isError">
        <swiper :options="swiperOption" class="swiper-card pointer-event-auto" ref="mySwiper" v-show="cardsList" :style="{ 'transform': `scale(${ratio}, ${ratio})` }" @transitionEnd="transitionEnd" @slideChange="slideChange" @resize="resize()">
          <swiper-slide class="swiper-slide" :class="{scale:titleClickIn && index === currentShowIndex}"  v-for="(item,index) in cardsList" :key="index">
            <Card :skinData= "skinData" :cardData="item" :taskIconMap='taskIconMap' @disable="disable"  @enable="enable" @clickTask="dealTaskClick"></Card>
          </swiper-slide>
        </swiper>
        <div class="swiper-button-prev pointer-event-auto"></div>
        <div class="swiper-button-next pointer-event-auto"></div>
      </div>
    </div>
    <div class="choice-children pointer-event-auto" v-if="showChildrens && showCard">
      <div class="switch-kid">
        <div class="mirror"></div>
        <div class="close-button"  @click="cancel">取消</div>
        <div class="confirm-button" @click="confirm">确定</div>
        <div class="right-scroll" @click="rightBtnClick"></div>
        <div class="left-scroll" @click="leftBtnClick"></div>
        <div class="kid-list-container">
          <div class="items-box" ref="list" @mousewheel="mouseScroll($event)">
            <div class="item" v-for="(item, index) in studentList" :class="itemClassObject(index)" @click="focusOnThisStudent(index)" :key="index">
              <div class="test">
                <div class="user-type" v-if="item.studentTypeSelectImg">
                  <img :src="item.studentTypeSelectImg" />
                </div>
                <div class="user-avatar">
                  <img :src="item.avatarUrl"  v-if="item.avatarUrl" />
                  <img :src="userAvatarCollectionProp['default']" v-else-if="!item.avatarUrl && (item.avatar == 'default' || !item.avatar)" />
                  <img :src="userAvatarCollectionProp[item.avatar]" v-else-if="!item.avatarUrl" />
                </div>
                <div class="english-name" :text="item.englishName" :isAutoSlide="false"></div>
                <div class="chinese-name" :text="item.name" :isAutoSlide="false"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <VkDialogAdultapp ref="adultappDialog" />
  </div>
</template>
<script>
import 'swiper/dist/css/swiper.css'
import Card from './card'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import service from '../../utils/service'
import networkError from '../comon/networkError'
import cookies from 'cookies-js'
import dragonUtil from '../../utils/dragon'
import VkDialogAdultapp from '../../common/vk_dialog_adultapp/index.vue'
import axios from 'axios'
const sa = window.sa || { track: () => 'mock window.sa' }

export default {
  data () {
    return {
      isShowGuidePage: true,
      swiperOption: {
        slidesPerView: 'auto',
        centeredSlides: true,
        speed: 200,
        touchAngle: 50,
        touchMoveStopPropagation: true,
        mousewheel: true,
        // forceToAxis: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
          // disabledClass: 'swiper-button-disabled'
        },
        lazy: {
          loadPrevNext: true
        },
        slideToClickedSlide: true
      },
      titleClickIn: false,
      cardsList: [],
      taskIconMap: {}, // 任务图片集合
      topTip: {},
      currentShowIndex: 0, // 当前选中的日子的 index
      nomalTip: false,
      hasAfterNextWeekClass: false,
      version: '',
      ratio: '1',
      isError: false,
      preDistance: 0,
      hasAnimationed: false,
      isMainPage: false,
      studentInfo: {},
      students: [],
      skinData: {},
      isRemind: false,
      showChildrens: false,
      devEnv: location.host.includes('dev')
    }
  },
  inject: ['skinData'],
  components: {
    swiper,
    swiperSlide,
    Card,
    networkError,
    VkDialogAdultapp
  },
  created () {
    try{
      this.skinData = JSON.parse(sessionStorage.getItem('skinData'))
    } catch(e) {
      console.log(e)
    }
    try{
      this.isRemind = sessionStorage.getItem('red-tip') == '1'
      console.log(this.isRemind)
    } catch(e) {
      console.log(e)
    }
    
    window.eventBus.$on('px-holiday-skin',  (data) =>{
      this.skinData = data
      try {
        sessionStorage.setItem('skinData', JSON.stringify(data))
      } catch(e){
        console.log(e)
      }
    })
    window.eventBus.$on('px-red-tip',  (data) =>{
      this.isRemind = data
      try {
        sessionStorage.setItem('red-tip', data ? '1' : '0')
      } catch(e){
        console.log(e)
      }
    })
    window.eventBus.$on('animationend', () => {
      this.hasAnimationed = true
      sessionStorage.setItem('newanimationend', '1')
    })
    const hasAnimationed = sessionStorage.getItem('newanimationend')
    if (hasAnimationed === '1') {
      setTimeout(()=>{
        this.hasAnimationed = true
      },1000)
    }
    this.getStudentBaseInfo()
  },
  computed: {
    // swiper () {
    //   return this.$refs.mySwiper && this.$refs.mySwiper.swiper
    // },
    showCard () {
      return this.hasAnimationed || this.devEnv
    }
  },
  beforeDestroy: function () {
    clearInterval(this.refresTime)
    window.removeEventListener('resize', this.setFitRatio)
    window.eventBus.$off('refreshCourseCard')
  },
  mounted () {
    this.setFitRatio()
    window.addEventListener('resize', this.setFitRatio)
    this.$nextTick(function(){
      this.getCourseCards(false)
    })
    this.refreshdata()
    window.eventBus.$on('refreshCourseCard', () => {
      this.getCourseCards(false)
    })
  },
  methods: {
    dealTaskClick(item) {
      if (item.materialCode === 'AI_LANGUAGE_PARTNER') {
        this.$refs.adultappDialog.show()
        return
      }
    },
    openUseInfo () {
      sa.track('page_click_viptrack', {
        'event_id': 'study_center_index_userinfo_enter_click'
      })
      const showStudents = setTimeout(()=> {
        this.showStudentList()
      }, 3000)
      window.Vm && window.Vm.$router && window.Vm.$router.push('/newmajor/user')

      window.eventBus.$on('px', function (info) {
        if (info.router === '/newmajor/user') {
          clearTimeout(showStudents)
        }
      })
      window.Vm && window.Vm.$playSound('click')
    },
    showStudentList () {
      if (this.students.length == 1) return
      this.showChildrens = true
    },
    // 获取个人信息
    getStudentBaseInfo () {
      const studentId = cookies.get('studentId')
      axios.get('/rest/learninggw/api/pc/homepage/getPcAppSetting', {
        params: {
          studentId: studentId,
          familyId: cookies.get('familyId'),
          source: dragonUtil.isOnDragonClient() ?  2 : 1,
          deviceNum: dragonUtil.isOnDragonClient() ? navigator.userAgent.match(/vipkid\/\d+(\.\d+)*/i)[0] + '/' + studentId : cookies.get('deviceNum')
        }
      }).then((res) => {
        const data = res.data.data
        console.log(data)
        if(data) {
          this.students = data.studentList
          this.studentInfo = data.studentList.filter((item) => {
            return item.id == studentId
          })[0] || {}
        }
      })
    },
    // 设置适配缩放比
    setFitRatio () {
      const designHeight = 768
      let clientHeight = document.documentElement.clientHeight
      const ratio = clientHeight / designHeight
      this.ratio = ratio
      return ratio
    },
    disable () {
      this.$refs.mySwiper && this.$refs.mySwiper.swiper.mousewheel.disable()
    },
    enable () {
      this.$refs.mySwiper && this.$refs.mySwiper.swiper.mousewheel.enable()
    },
    goto (index) {
      sa.track('page_click_viptrack', {
        'event_id': 'study_center_left_course_enter_click',
        'content': index === 1 ? '左侧入口' : '右侧入口'
      })
      VK.push('/newcourse')
    },
    getCourseCards (refresh) {
      service.getCourseCardsData().then(res => {
        if (res.status) {
          this.isError = false
          const data = res.data
          this.cardsList = data.courseCards
          // 优先取topTip，如果不存在则取extendMap
          if (data.topTip) {
            this.topTip = data.topTip
          } else if (data.extendMap) {
            this.topTip = data.extendMap
          }
          this.taskIconMap = data.taskIconMap ? data.taskIconMap : {}
          this.hasAfterNextWeekClass = data.hasAfterNextWeekClass
          // 定位
            this.cardsList.forEach((element, index) => {
              if (element.locate && !refresh) {
                setTimeout(()=>{
                  this.currentShowIndex = index
                  this.$refs.mySwiper && this.$refs.mySwiper.swiper.slideTo(index, 100, true)
                }, 200)
                sa.track('page_click_viptrack', {
                  'str1': JSON.stringify(element.button),
                  'str2': JSON.stringify(element.title)
                })
              }
            })
        } else {
          this.isError = true
        }
      })
    },
    refreshdata () {
      this.refresTime = setInterval(() => {
        setTimeout(() => {
          this.getCourseCards(true)
        }, 0)
      }, 30000)
    },
    // 顶部课程状态定位
    async location (onlineClassId, tipType) {
      sa.track('page_click_viptrack', {
        'event_id': 'study_center_index_classnotice_click',
        'content': tipType
      })
      if (typeof Vm.$playSound === 'function') {
        Vm.$playSound("click")
      }
      // 判断当前选中的是否是补做的课程,如果是当前卡片放大,不是则滑动到要跳转的卡片
      let index = this.cardsList.findIndex((el) => {
        return el.onlineClass && onlineClassId === el.onlineClass.onlineClassId
      })
      if (index > -1) {
        if (this.$refs.mySwiper && this.$refs.mySwiper.swiper.activeIndex === index) {
          this.currentShowIndex = index
          if (tipType === 'STUDY_PLAN_REDO') {
            this.$showToast('请完成本节课的任务再领取奖励哦~')
          }
        } else {
          this.$refs.mySwiper && this.$refs.mySwiper.swiper.slideTo(index, 300, true)
          await this.sleep(300)
        }
        if (this.titleClickIn) return false
        this.titleClickIn = true
        await this.sleep(4000)
        this.titleClickIn = false
      }
    },
    sleep (time = 0) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve()
        }, time)
      })
    },
    resize () {
      this.$refs.mySwiper && this.$refs.mySwiper.swiper.update()
    },
    transitionEnd () {
      this.preDistance === 0 && (this.preDistance = this.$refs.mySwiper && this.$refs.mySwiper.swiper.translate)
    },
    // 获取当前选中的卡片index
    slideChange () {
      this.currentShowIndex = this.$refs.mySwiper && this.$refs.mySwiper.swiper.activeIndex
      const distance = this.$refs.mySwiper && this.$refs.mySwiper.swiper.translate
      this.$emit('positionChage', distance - this.preDistance)
      this.preDistance = distance
    }
  }
}
</script>
<style lang="stylus" scoped>
@import '../../assets/css/mixs.styl'
  #px-app {
    pointer-events:none;
    &.dev {
      background: #744dee;
    }
  }
  .pointer-event-none {
    pointer-events:none;
  }
  .pointer-event-auto {
    pointer-events:auto;
  }
  .left-info
    min-width: 237px;
    float: left;
    height: 72px;
    position: fixed
    top: 20px
    left: 30px
    &:hover
      transform: translateZ(0) scale(1.05)
    &:active
      transform: translateZ(0) scale(0.95)
    .user-avatar
      position absolute
      top 0
      left 0
      wh(60px,60px)
      border-radius 50%
      border:6px solid #4d33cc;
      z-index 1
      img
        wh(100%,100%)
        border-radius 50%
        &.default-img
          background-color: #f2f2f2
    .red-tip
      wh(12px,12px)
      background-color #fe7313
      border-radius 50%
      position absolute
      left 54px
      top -6px
    .myself-panel
      background: #4d33cc;
      border-radius: 0px 100px 100px 0px;
      position: absolute
      top 6px
      left 30px
      height: 60px
      min-width: 160px
      width: 202px
      padding-left 44px
      box-sizing: border-box;
      display flex
      align-items: center;
      .text
        display inline-block
        width: 90%;
        height: 33px;
        font-size: 24px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: center;
        color: #ffffff;
        line-height: 33px;
        part-ellipsis()
  .content
    // background-color rgba(80,227,194,1)
    position absolute
    top 88px
    bottom:88px
    width 100%
    &::-webkit-scrollbar { width: 0 !important }
//自动移滚动条样式
  ::-webkit-scrollbar
    width: 5px;
    height: 5px
  ::-webkit-scrollbar-thumb
    border-radius: 10px
    height 10px
    background-color: rgba(50,50,50,.0)
  ::-webkit-scrollbar-track
    display none
    border-radius: 10px
    background-color: rgba(50,50,50,.0)
  .container
    // background-color red
    height 100%
    overflow hidden
    .nomal-tip
      width: 24px
      font-sw(18px,400)
      font-family:PingFangSC-Regular,PingFang SC
      color:rgba(255,255,255,.8)
      line-height:25px
      z-index 2
      cursor pointer
      span
        color #4DFFFF
      &.left
        position: absolute
        top: 50%
        left 32px
        margin-top: -200px
      &.right
        position: absolute
        top: 50%
        right 32px
        margin-top: -200px
    .course-tip
      height:40px
      min-width:240px
      padding:0 15px
      background: rgba(0,0,0,.2)
      border-radius: 22px
      position absolute
      top -10px
      left 50%
      transform: translateX(-50%);
      color:#fff
      text-align center
      z-index: 2
      box-sizing: border-box;
      display: flex
      align-items: center
      justify-content: center
      .tip-cont
        display: flex
        align-items: center
        justify-content: center
        cursor: pointer
      label
        cursor: pointer
      .highlight
        color #4DFFFF
        margin 0 4px
        cursor pointer
      .img
        wh(20px)
    .cont
      wh(100%,100%)
      .swiper-button-prev , .swiper-button-next
        outline none
        wh(100px,100px)
        outline: none;
        background-position: center center;
        background-repeat: no-repeat;
        transform: rotate(0);
        &:hover
          transform: scale(1.2) translateZ(0) translate3d(0,0,0)
        &:active
          transform: scale(0.95) translateZ(0) translate3d(0,0,0)
        &.swiper-button-disabled
          display: none
        &:after
          content: ''
      .swiper-button-prev
        background-image: url('../../assets/img/learningplan/left-arrow.png')
        background-size 100% 100%
      .swiper-button-next
        background-image: url('../../assets/img/learningplan/right-arrow.png')
        background-size 100% 100%
      .swiper-card
        wh(100%,100%)
        overflow: visible
        .scale
          animation:scaleDraw 3s  1 ease-in-out;
        >>> .swiper-slide
              width auto
              display: flex;
              align-items: center;
              transition: 300ms;
              transform translateZ(0) scale(0.9)
        >>> .swiper-slide-active
              transform: translateZ(0) scale(1)
  @keyframes scaleDraw
      0%
        transform: scale(1) translateZ(0);  /*开始为原始大小*/
      25%
        transform: scale(1.05) translateZ(0); /*放大1.1倍*/
      50%
        transform: scale(1) translateZ(0);
      75%
        transform: scale(1.05) translateZ(0)
</style>
<style lang="stylus" scoped>
.mirror
  background-color: rgba(0,0,0,0.8)
  position: fixed
  top: 0
  left: 0
  bottom: 0
  right: 0
  z-index: 9
.close-button
  position: absolute
  top: 50%
  left: 50%
  width: 160px
  height: 50px
  background-color: #C7B8E6
  z-index: 20
  margin: 206px 0 0 -180px
  text-align: center
  color: #fff
  line-height: 50px
  cursor: pointer
  font-size: 24px
  border-radius: 88px
  &:hover
    opacity: 0.8
.switch-kid
  width: 100%
  height: 100%
  overflow:hidden
  position: relative
  z-index: 20
.kid-list-container
  position: relative
  top: 50%
  left: 50%
  z-index: 10
  margin: -283px 0 0 -573px
  text-align: center
  display: inline-block
  vertical-align: middle
  height: 500px
  width: 100%
  overflow: hidden
  box-sizing: border-box
  .items-box
    position: relative
    top: 28%
    white-space: nowrap
    margin-top: 18px
  .item
    width: 266px
    height: 266px
    background-color: #fff
    border-radius: 30px
    display: inline-block
    position: relative
    cursor: pointer
    opacity: 0
    transform: scale(0)
    margin: 0 10px
    transition: all 0.2s linear
    border-radius: 34px
    .test
      top: 8px
      left: 8px
      width: 250px
      height: 250px
      background-color: #fff
      border-radius: 30px
      display: inline-block
      position: absolute
      cursor: pointer
    &.focus
      opacity: 1
      transform: scale(1)
      background: linear-gradient(135deg, rgba(221,204,255,1) 0%,rgba(221,204,255,0.1) 20%,rgba(221,204,255,0.1) 80%,rgba(221,204,255,1) 100%)
      // &:before
      //   content: ''
      //   position: absolute
      //   top: -8px
      //   left: -8px
      //   width: 266px
      //   height: 266px
      //   background: linear-gradient(135deg, rgba(221,204,255,1) 0%,rgba(221,204,255,0.1) 20%,rgba(221,204,255,0.1) 80%,rgba(221,204,255,1) 100%)
      //   border-radius:34px
      //   z-index: -1
      &:after
        content: ''
        position: absolute
        top: 8px
        left: 8px
        width: 86px
        height: 90px
        background-image: url(../../assets/img/user_info/current_label.png)
        background-size: 100% 100%
    &.prefocus, &.afterfocus
      transform: scale(0.75187)
      opacity: 0.9
    &.prefocus_2, &.afterfocus_2
      opacity: 0.5
    &.prefocus_2
      transform: scale(0.6) translateX(86px)
    &.afterfocus_2
      transform: scale(0.6) translateX(-86px)

.right-scroll, .left-scroll
  width: 76px
  height: 104px
  background-repeat: no-repeat
  background-size: 100% 100%
  position: fixed
  top: 43%
  z-index: 15
  cursor: pointer

.right-scroll
  background-image: url(../../assets/img/user_info/user_right.png)
  right: 0
.left-scroll
  background-image: url(../../assets/img/user_info/user_left.png)
  left: 0
.user-avatar
  position: absolute
  top: 24px
  left: 60px
  width: 110px
  height: 110px
  border: 10px solid #F5F0FF
  border-radius: 100%
  img
    width: 100%
    height: 100%
    border-radius: 100%
.user-type
  position: absolute
  width: 36px
  height: 24px
  left: 185px
  bottom: -24px
  z-index: 9
  img
    width: 100%
    height: 100%
.english-name
  position: absolute
  top: 156px
  left: 8px
  width: 236px
  color: #6F6680
  font-size: 28px
  text-align: center
  font-weight: bold
.chinese-name
  position: absolute
  top: 195px
  left: 75px
  width: 100px
  color: #A7A1B3
  font-size: 18px
  text-align: center
.confirm-button
  position: absolute
  top: 50%
  left: 50%
  width: 160px
  height: 50px
  background:linear-gradient(232deg,#FF8F45 0%,rgba(255,92,119,1) 100%);
  box-shadow:0px 4px 10px 0px rgba(255,116,0,0.5);
  z-index: 20
  margin: 206px 0 0 30px
  text-align: center
  color: #fff
  line-height: 50px
  cursor: pointer
  font-size: 24px
  border-radius: 88px
  &:hover
    opacity: 0.8
</style>
