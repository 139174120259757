<template>
  <div>
    <div class="card" v-if="cardData.cardType =='COURSE'">
      <div class="card-bg" :style="{backgroundImage: skinData && skinData.courseCardTop ? `url(${skinData.courseCardTop})` :''}"></div>
      <div class="course-content"  @click="gotoDetail(cardData.courseInfo.courseDetailRoute)">
        <div class="course-bg" :style="{backgroundImage: cardData.courseInfo ? `url(${cardData.courseInfo.courseTypeImg})` :''}">
          <div class="subject-bg" :style="{backgroundImage: cardData.courseInfo ? `url(${cardData.courseInfo.courseTypeFrontImg})`:''}"></div>
        </div>
        <div class="container">
          <div class="teacher-cont">
            <div class="head-portrait">
              <img :src="getImg(cardData.teacherList[0].avatar)" alt="" v-if="cardData.teacherList && cardData.teacherList[0].avatar">
              <img src="@/assets/img/homepage/empty.png" class="default-img" alt="" v-else>
              <div class="name">{{cardData.teacherList[0].showName}}</div>
            </div>

          </div>
          <div class="info-cont">
            <div class="title">
              <div class="tag" :style="{backgroundColor: (cardData.courseInfo && cardData.courseInfo.courseTypeBgColor) ? cardData.courseInfo.courseTypeBgColor : '#FE7110'}">
                <span class="tag-icon">
                  <img :src="cardData.courseInfo ? cardData.courseInfo.courseTypeIcon : ''" :onerror="defaultImg" alt="" v-if="cardData.courseInfo && cardData.courseInfo.courseTypeIcon"/>
                  <img src="@/assets/img/course/tag_icon_enlish@2x.png" alt="" v-else/>
                </span>
                <span class="tag-name">{{cardData.shortName}}</span>
              </div>
              <div class="lesson-name">{{cardData.title}}</div>
              <img src="@/assets/img/course/arrow_primary@2x.png" alt="" class="arrow-icon" v-if="cardData.courseInfo && cardData.courseInfo.courseDetailRoute">
            </div>
            <div class="topic-name" v-if="cardData.lessonTopic!== '' && cardData.cardType === 'COURSE'">{{ cardData.lessonTopic }}</div>
            <div class="time-cont">
              <template v-if="cardData.time.type!=='TODAY'">
                <div :class="cardData.time.mutlTimes ? 'segmentDisplay': 'defaultDisplay'">
                  <div class="date">{{cardData.time.date}}</div>
                  <div class="week">{{cardData.time.day}}</div>
                </div>
                <div v-if="cardData.time.mutlTimes">
                  <div class="timeBox" :class="mutlTime.current ? 'activeTime' : ''" v-for="(mutlTime, index) in cardData.time.mutlTimes" :key="index">
                    <span class="timeMark">{{mutlTime.title}}</span>
                    <span class="time">{{mutlTime.time}}</span>
                  </div>
                </div>
                <div v-else class="time">{{cardData.time.time}}</div>
              </template>
              <template v-else>
                <template v-if="!cardData.time.mutlTimes">
                  <div class="time big">{{cardData.time.time}}</div>
                  <div class="week">{{cardData.time.day}}</div>
                </template>
                <template v-else>
                  <div class="week segmentDisplayWeek">{{cardData.time.day}}</div>
                  <div>
                    <div class="timeBox" :class="mutlTime.current ? 'activeTime' : ''" v-for="(mutlTime, index) in cardData.time.mutlTimes" :key="index">
                      <span class="timeMark">{{mutlTime.title}}</span>
                      <span class="time">{{mutlTime.time}}</span>
                    </div>
                  </div>
                </template>
              </template>
            </div>
            <vkButton class="bottom-btn"
              :type="cardData.button.showType"
              :buttonType="cardData.button.buttonType"
              :text="cardData.button.text"
              :color="cardData.button.color"
              :havbg="true"
              @goto='buttonClick(cardData.button,cardData.onlineClass)'
              >
            </vkButton>
          </div>
        </div>

      </div>
      <div class="center">
        <div class="task-cont" v-if="cardData.courseInfo" ref="taskcont">
          <ul class="task-line clearfix" ref="taskline" v-if="cardData.courseInfo.taskInfo.tasks && cardData.courseInfo.taskInfo.tasks.length>0" @mouseenter="changeActive(cardData.courseInfo.taskInfo.tasks.length)" @mouseleave="removeActive(cardData.courseInfo.taskInfo.tasks.length)">
            <li class="task" :class="[item.status==='LOCK'?'gray':'']" v-for="(item,index) in cardData.courseInfo.taskInfo.tasks" :key="index" @click="goToPageUrl(item, cardData.onlineClass)">
              <div class="task-box">
                <div class="task-img">
                  <img :src="getImg(taskIconMap[item.materialCode])" alt="" :onerror="defaultImg">

                </div>
                <div class="name">{{item.taskName}}</div>
                <div class="status" :class="item.status =='UNFINISH' ? 'gray':''">
                  <img src="@/assets/img/course/task_completed@2x.png" alt="" v-if="item.status =='FINISH' || item.status =='UNFINISH'">
                  <img src="@/assets/img/course/task_unlock@2x.png" alt="" v-if="item.status =='LOCK'">
                </div>
                <div class="award-cont" :class="item.taskAward && item.taskAward.awardStatus !== 'RECEIVE' && item.status =='UNFINISH' ? 'gray':''" v-if="item.taskAward && item.taskAward.awardStatus !== 'RECEIVE'">
                  <img :src="item.taskAward.awardPic" alt="" :class="[{'shining': (item.taskAward.awardStatus == 'NOT_RECEIVE' && item.taskAward.status == 'COMPLETE')}]">
                  <span class="num">{{item.taskAward.awardNumber}}</span>
                </div>
              </div>

            </li>
          </ul>
          <div class="null" v-else>
            <div class="img-box">
              <img :src="cardData.courseInfo.taskInfo.tipImg" alt="" :onerror="defaultImg">
            </div>
            <div class="desc">{{cardData.courseInfo.taskInfo.tipText}}</div>
          </div>
        </div>
      </div>
      <div class="bottom" v-if="Object.keys(allTaskAward).length > 0 && cardData.courseInfo && cardData.courseInfo.taskInfo.tasks.length>0" :class="[{'canReceive':allTaskAward.awardStatus=='NOT_RECEIVE' && allTaskAward.status == 'COMPLETE'},{'received':allTaskAward.awardStatus=='RECEIVE'}]" @click="getAllAward(allTaskAward)" >
        <div class="award-icon" v-if="allTaskAward.awardStatus !== 'RECEIVE'">
          <template v-if="allTaskAward.awardPic && allTaskAward.status !== 'COMPLETE'">
            <img :src="allTaskAward.awardPic"
              alt="" class="icon"
              :class="[{'shining': (allTaskAward.awardStatus == 'NOT_RECEIVE' && allTaskAward.status == 'COMPLETE')}]">
          </template>
          <template v-else>
            <img src="https://s.vipkidstatic.com/cum/tatic/images/learning_center/homepage/revision/activity/enery_store.png"
              alt="" class="icon"
              :class="[{'shining': (allTaskAward.awardStatus == 'NOT_RECEIVE' && allTaskAward.status == 'COMPLETE')}]">
          </template>
          <span v-if="allTaskAward.awardStatus !== 'RECEIVE' && allTaskAward.status !== 'COMPLETE'" class="bg-icon" :class="[{'shining': (allTaskAward.awardStatus == 'NOT_RECEIVE' && allTaskAward.status == 'COMPLETE')}]"></span>
        </div>
        <span class="num" v-if="allTaskAward.awardStatus == 'RECEIVE' && allTaskAward.status == 'COMPLETE'">已领取</span>
        <span class="num" v-else>{{allTaskAward.awardNumber}}</span>
      </div>
    </div>
    <div class="specialCard" v-if="cardData.cardType =='OTHER'">
      <div class="card-bg" :style="[{backgroundImage: skinData && skinData.courseSpecialCardTop ? `url(${skinData.courseSpecialCardTop})` :''}]"></div>
      <div class="cont">
        <h5 class="title">{{cardData.title}}</h5>
        <p class="desc">{{cardData.description}}</p>
        <div class="img-box">
          <img :src="cardData.imageUrl" alt="" :onerror="defaultImg">
        </div>
        <div class="status">
          <vkButton class="bottom-btn"
              :type="cardData.button.showType"
              :text="cardData.button.text"
              :color="cardData.button.color"
              v-if="cardData.button"
              @goto='buttonClick(cardData.button,cardData.onlineClass,cardData.cardType,cardData.title)'
          ></vkButton>
          <vkButton class="bottom-btn bottom-btn-periodic"
            v-if="checkPeriodicBtn(cardData)"
            :type="cardData.periodicButton.showType"
            :text="cardData.periodicButton.text"
            :color="cardData.periodicButton.color"
            @goto='buttonClick(cardData.periodicButton,cardData.onlineClass,cardData.cardType,cardData.title)'
          ></vkButton>
          <img class="complete-icon" :src="require('@/assets/img/course/task_completed@2x.png')" alt="" v-if="cardData.taskAward && cardData.taskAward.status == 'COMPLETE'"/>
        </div>
        <div class="award-cont" @click="getAllAward(cardData.taskAward,cardData.cardType,cardData.title)" v-if="cardData.taskAward">
          <img :src="cardData.taskAward.awardPic" alt="" :class="[{'shining': (cardData.taskAward.awardStatus == 'NOT_RECEIVE' && cardData.taskAward.status == 'COMPLETE')}]">
          <span class="num">{{cardData.taskAward.awardNumber}}</span>
        </div>
      </div>

    </div>
  </div>

</template>
<script>
import vkButton from './new_button'
import service from '../../utils/service'
import link from '../../utils/link'
import newCourse from '../../common/course/newCourse'
import { optimizeImgUrl, horwheel, unbindHorwheel } from '../../utils/utils'

const sa = window.sa || { track: () => 'mock window.sa' }
export default {
  name: 'card',
  data () {
    return {
      taskList: [],
      courseInfo: {}, // 课程其他信息
      allTaskAward: {},
      defaultImg: 'this.src="' + require('../../assets/img/homepage/empty.png') + '";this.setAttribute("class", "error"); ',
      isClick: false,
      onlineClass: {},
      taskcontent: '',
      allTaskContent: ''
    }
  },
  props: {
    cardData: {
      type: Object,
      default: () => {},
      required: true
    },
    taskIconMap: {
      type: Object,
      default: () => {},
      required: true
    },
    ratio: {
      type: Number,
      default: 1
    },
    skinData: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  components: {
    vkButton
  },
  computed: {
    // getTaskImg () {
    //   // return this.taskIconMap.
    // }
  },
  mounted () {
    if (this.cardData.courseInfo) {
      this.courseInfo = this.cardData.courseInfo || {}
      this.allTaskAward = (this.cardData.courseInfo && this.cardData.courseInfo.taskInfo.taskAward) || {}
    }
    this.onlineClass = this.cardData.onlineClass ? this.cardData.onlineClass : {}
  },
  methods: {
    getImg (url) {
      return optimizeImgUrl(url)
    },
    changeActive (num) {
      if (num < 5) return false
      horwheel(this.$refs.taskcont)
      this.$emit('disable')
    },
    removeActive (num) {
      if (num < 5) return false
      unbindHorwheel(this.$refs.taskcont)
      this.$emit('enable')
    },
    // 课程详情页
    gotoDetail (route) {
      if (window.Vm && typeof Vm.$playSound === 'function') {
        Vm.$playSound("click")
      }
      if (route) link.handlelink(route)

      sa.track('page_click_viptrack', {
        'event_id': 'study_center_index_classinfo_click',
        'course_id': this.onlineClass.courseId ? this.onlineClass.courseId : '',
        'lesson_id': this.onlineClass.lessonId ? this.onlineClass.lessonId : ''
      })
    },
    async goToPageUrl (item) {
      if (window.Vm && typeof Vm.$playSound === 'function') {
        Vm.$playSound("click")
      }
      // 交给外部处理
      if (item.materialCode === 'AI_LANGUAGE_PARTNER') {
        this.$emit('clickTask', item)
        return
      }
      if (item.status === 'LOCK') {
        this.$showToast('下课后才能进行课后学习~')
        this.taskcontent = '未解锁'
        return
      }
      if (item.taskAward) {
        if (item.taskAward.status !== 'COMPLETE') {
          this.taskcontent = '待完成'
        } else {
          if (item.taskAward.awardStatus === 'NOT_RECEIVE') {
            this.taskcontent = '可领奖'
          }
          if (item.taskAward.awardStatus === 'RECEIVE') {
            this.taskcontent = '已领奖'
          }
        }
      }
      sa.track('page_click_viptrack', {
        'event_id': 'study_center_index_classtask_click',
        'course_id': this.onlineClass.courseId ? this.onlineClass.courseId : '',
        'lesson_id': this.onlineClass.lessonId ? this.onlineClass.lessonId : '',
        'content_title': item.taskName,
        'content_id': item.id,
        'content': this.taskcontent
      })
      // 跳转链接

      // 判断是否是领奖状态,领奖状态直接领奖
      if (item.taskAward && (item.taskAward.awardStatus === 'NOT_RECEIVE' && item.taskAward.status === 'COMPLETE')) {
        let status = await this.getAward(item.taskAward)
        if (status) {
          item.taskAward.awardStatus = 'RECEIVE'
        }
      } else {
        link.handlelink(item.router)
      }
    },
    // 领奖方法
    async getAward (taskAward) {
      if (this.isClick) {
        return false
      }
      this.isClick = true
      const res = await service.receiveReward({id: taskAward.taskId})
      if (res.status) {
        this.isClick = false
        let bongAnimation = window.defaultModule.dynamicComponent.bongAnimation
        bongAnimation().then(({ default: info }) => {
          this.currentComponent = info
          this.$modalShow({
            model: info,
            type: taskAward.awardType,
            number: taskAward.awardNumber.toString(),
            iconUrl: taskAward.awardPic ? taskAward.awardPic : '',
            closeDialog: () => {
              this.$modelClose()
            }
          })
        })
        return true
      } else {
        this.$showToast('领取失败')
        return false
      }
    },
    // 获取卡片总奖励
    async getAllAward (item, cardType, title) {
      if (item.awardStatus === 'NOT_RECEIVE' && item.status === 'COMPLETE') {
        this.allTaskContent = '可领奖'
        let getstatus = await this.getAward(item)
        if (getstatus) item.awardStatus = 'RECEIVE'
      } else {
        if (cardType !== 'OTHER' && item.awardStatus !== 'RECEIVE') {
          this.$showToast('完成所有课程任务才能奖励')
        }
        if (item.awardStatus === 'RECEIVE') {
          this.allTaskContent = '已领奖'
        } else {
          this.allTaskContent = '不可领奖'
        }
      }
      if (cardType === 'OTHER') {
        sa.track('page_click_viptrack', {
          'event_id': 'study_center_index_specialcard_award_click',
          'content_title': title,
          'content': this.allTaskContent
        })
      } else {
        sa.track('page_click_viptrack', {
          'event_id': 'study_center_index_classaward_click',
          'course_id': this.onlineClass.courseId ? this.onlineClass.courseId : '',
          'lesson_id': this.onlineClass.lessonId ? this.onlineClass.lessonId : '',
          'content': this.allTaskContent
        })
      }
    },
    checkPeriodicBtn (cardData) {
      return cardData && cardData.hasOwnProperty('periodicButton') && (typeof cardData.periodicButton === 'object')
    },
    // 按钮点击
    buttonClick (button, onlineClass, cardType, title) {
      newCourse.courseButtonClick({
        route: button.route,
        onlineClassId: onlineClass ? onlineClass.onlineClassId : ''
      })

      if (cardType === 'OTHER') {
        sa.track('page_click_viptrack', {
          'event_id': 'study_center_index_specialcard_button_click',
          'content_title': title
        })
      } else {
        let arr = button.text
        let buttonText = ''
        arr.forEach(element => {
          if (element.contentType === 'TEXT') {
            buttonText = element.content
          }
        })
        sa.track('page_click_viptrack', {
          'event_id': 'study_center_index_enterclass_click',
          'course_id': this.onlineClass.courseId ? this.onlineClass.courseId : '',
          'lesson_id': this.onlineClass.lessonId ? this.onlineClass.lessonId : '',
          'content': buttonText
        })
      }
      if (window.Vm && typeof Vm.$playSound === 'function') {
        Vm.$playSound("click")
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
 @import '../../assets/css/mixs.styl'
  .card
    wh(640px,420px)
    border-radius: 80px
    background-color #fff
    padding 16px 20px 0 20px
    box-sizing border-box
    position relative
    .gray
      filter: gray
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      filter: grayscale(100%);
      filter: gray;
      opacity .7
    .card-bg
      wh(100%,86px)
      background-image: url('../../assets/img/course/card-bg.png')
      background-size 100% 100%
      position: absolute;
      top: -48px;
      left: 0;
    .course-content
      font-size 18px
      height: 160px;
      position relative
      z-index: 1
      cursor pointer
      .course-bg
        wh(100%,100%)
        display flex
        background-image: url('../../assets/img/course/card_bg-default.png')
        background-size cover
        .subject-bg
          wh(100%,100%)
          background-image: url('../../assets/img/course/card_bg-default-front.png')
          background-size contain
      .container
        position absolute
        left 0
        right 0
        top 0
        bottom 0
        display flex
      .teacher-cont
        wh(120px,120px)
        background #ffffff
        border-radius 40px
        padding 8px
        box-sizing border-box
        display flex
        flex-direction: column;
        margin 20px 0 20px 20px
        overflow: hidden;
        flex: none
        .head-portrait
          flex 1
          position relative
          img
            wh(100%,100%)
            border-radius 32px
            position: absolute;
            background-color: #f2f2f2;
            &.default-img
              background-color: #f2f2f2;
          .name
            // part-h-lh(28px,28px)
            font-sc(18px,#666)
            text-align: center;
            position: absolute;
            bottom: -3px;
            left: 0;
            right: 0;
            background-color: #fff;
            border-radius: 32px 40px;
            part-ellipsis()
            padding:0 5px
      .info-cont
        flex:1
        margin-left: 16px;
        .title
          flex-box()
          padding-top: 14px
          .tag
            part-h-lh(36px,36px)
            background-color: #fe7719;
            border-radius: 12px;
            text-align center
            margin-right 8px
            display flex
            .tag-icon
              wh(32px,36px)
              border-radius: 8px 0px 0px 8px;
              background-color rgba(0,0,0,0.05)
              display: inline-flex;
              align-items: center;
              justify-content: center;
              img
                wh(28px,28px)
            .tag-name
              display inline-block
              padding 0 8px
              font-sc(18px,#fff)
              font-family: PingFangSC-Medium, PingFangSC, "Microsoft YaHei", STHeiti, 'Helvetica Neue', Helvetica, Arial, 'sans-serif'
          .lesson-name
            flex 1
            part-h-lh(40px)
            font-sc(28px,#fff)
            width 0
            part-ellipsis()
            text-align: left
            transform: translate3d(0,0,0)
            font-family: PingFangSC-Medium, PingFangSC, "Microsoft YaHei", STHeiti, 'Helvetica Neue', Helvetica, Arial, 'sans-serif'
          .arrow-icon
            wh(20px,20px)
            margin-right 24px
            &:hover
              transform: translateZ(0) scale(1.05)
            &:active
              transform: translateZ(0) scale(0.95)
        .topic-name
          font-size: 26px
          color: #fff
          line-height: 36px
          overflow: hidden
          white-space: nowrap
          text-overflow: ellipsis
          max-width: 74%
        .time-cont
          display: flex
          align-items: center;
          margin-top 2px
          height 60px
          transform: translate3d(0,0,0)
          .segmentDisplay
            display flex
            flex-direction column
            .date
              font-sc(18px,#fff)
            .week
              font-sc(18px,#fff)
          .defaultDisplay
            display flex
          .timeMark
            // width: 60px;
            // height: 24px;
            // opacity: 0.2;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 14px;
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Semibold;
            font-weight: 600;
            color: #ffffff;
            // padding: 5px 9px;
            height: 24px;
            width: 60px;
            line-height: 24px;
            text-align: center;
            margin 0 12px 0 10px
          .date
            font-sc(28px,#fff)
            transform: translate3d(0,0,0)
          .week
            font-sc(28px,#fff)
            margin-right 8px
            transform: translate3d(0,0,0)
          .segmentDisplayWeek
            font-sc(18px,#fff)
            font-family PingFangSC, PingFangSC-Semibold
            font-weight 600
          .timeBox
            display flex
            align-items: center;
            &:first-child
              // margin-bottom: 4px;
            .time
              font-sc(24px,#fff)
              font-family: PingFangSC, PingFangSC-Semibold
              font-weight: 600
              opacity 0.8
          .activeTime
            .timeMark
              background rgba(255, 255, 255, 1)
              color: #fe7110
            .time
              font-size: 32px
              font-family: PingFangSC, PingFangSC-Semibold
              font-weight: 600
              opacity 1
          .time
            font-sc(32px,#fff)
            transform: translate3d(0,0,0)
            &.big
              font-size 48px
              margin-right 8px
        .bottom-btn
          position absolute
          right 20px
          bottom 8px
    .center
      .task-cont
        overflow-x: scroll
        overflow-y: hidden
        &::-webkit-scrollbar
            display: none
        .task-line
          text-align: center;
          padding:24px 0 12px 0
          white-space: nowrap;
          .task
            margin-right: 40px;
            display inline-block
            cursor pointer
            min-width 72px
            .task-box
              display: flex;
              flex-direction: column;
              align-items: center;
            &.gray
              filter: gray
              -webkit-filter: grayscale(100%);
              -moz-filter: grayscale(100%);
              -ms-filter: grayscale(100%);
              -o-filter: grayscale(100%);
              filter: grayscale(100%);
              filter: gray;
              opacity .7
            &:first-child
              .status
                &:before
                  display none
            &:last-child
              margin-right 0
            .task-img
              &:hover
                  transform: translateZ(0) scale(1.05)
              &:active
                transform: translateZ(0) scale(0.95)
              img
                wh(72px,72px)
                &.error
                  background-color: #f2f2f2;
                  border-radius: 24px;
            .name
              part-h-lh(25px)
              font-sc(18px,#666666)
              text-align: center
              font-weight bold
              font-family: PingFangSC-Regular, PingFangSC, "Microsoft YaHei", STHeiti, 'Helvetica Neue', Helvetica, Arial, 'sans-serif'
            .status
              margin-top 11px
              position relative
              &:before
                content: '';
                display: inline-block;
                wh(60px,4px)
                position: absolute;
                top: 12px;
                right: 40px;
                background: #4984fd;
                border-radius: 2px;
                z-index: 1;
                box-sizing: border-box;
              img
                wh(28px,28px)
          .award-cont
            display flex
            align-items: center;
            justify-content:center;
            margin-top: 3px;
            wh(100%,24px)
            img
              wh(24px,24px)
              margin-right 4px
              &.shining
                animation: shake 1.4s linear infinite
            .num
              font-sc(16px,#8091c0)
        .null
          display flex
          align-items center
          flex-direction: column;
          margin-top: 20px
          img
            wh(272px,160px)
          .desc
            font-sc(18px,#9b9b9b)
            font-family: PingFangSC-regular, PingFangSC, "Microsoft YaHei", STHeiti, 'Helvetica Neue', Helvetica, Arial, 'sans-serif';
    .bottom
      wh(212px,52px)
      background-image: url('../../assets/img/course/energystone_bg_unfinished@2x.png')
      background-size cover
      position absolute
      left 50%
      margin-left -106px
      bottom -9px
      display flex
      align-items: center;
      justify-content: center;
      cursor pointer
      &.canReceive
        background-image: url('../../assets/img/course/energystone_bg_full@2x.png')
        .num
          color #ffffff
      &.received
        background-image: url('../../assets/img/course/energystone_bg_checked@2x.png')
        .num
          color #4DA731
      .award-icon
        wh(35px,35px)
        position relative
        .icon
          wh(35px,35px)
          &.shining
            animation: shake 1.4s linear infinite
        .bg-icon
          display inline-block
          position absolute
          top 0
          right 0
          wh(20px,20px)
          background-image: url('../../assets/img/course/sparking@2x.png')
          background-size cover
          &.shining
            animation: starshake 1.4s ease-in infinite;
      .num
        font-sc(24px,#8091c0)
  .specialCard
    wh(340px,420px)
    background: #ffffff;
    border-radius: 80px
    position relative
    margin-left 15px
    .card-bg
      wh(100%,86px)
      background-image: url('../../assets/img/course/card_bg_Small.png')
      background-size 100% 100%
      position: absolute;
      top: -48px;
      left: 0;
    .cont
      display flex
      flex-direction column
      align-items center
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 0 26px;
      box-sizing border-box
    .title
      font-sc(24px,#333333)
      text-align center
      line-height 33px
      margin 14px 0 7px 0
    .desc
      font-sc(18px,#999999)
      height 56px
      text-align center
      margin-bottom 18px
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 28px;
      font-family: PingFangSC-regular, PingFangSC, "Microsoft YaHei", STHeiti, 'Helvetica Neue', Helvetica, Arial, 'sans-serif';
    .img-box
      margin-bottom 32px
      wh(136px,136px)
      img
        wh(136px,136px)
    .status
      >>>.vk-button .btn
        height 48px
      .complete-icon
        wh(28px,28px)
    .award-cont
      display flex
      align-items center
      justify-content center
      position absolute
      bottom 0
      padding-bottom 30px
      cursor pointer
      img
        wh(26px)
        margin-right 4px
        border-radius 5px
        &.shining
          animation: shake 1.4s linear infinite
      .num
        font-sc(24px,#8091c0)
  @keyframes shake
    0%,58%
      transform: scale(1) translateZ(0) rotate(0)
    79%
      transform: scale(1.2) translateZ(0) rotate(-15deg)

  @keyframes starshake
    0%,58%
      opacity 0
    79%
      opacity 1
  .bottom-btn-periodic
    margin-top: 10px
</style>
