import { render, staticRenderFns } from "./app.vue?vue&type=template&id=b4f8bc86&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/vipkid/agile_build/static/6048638e8896a73f17d49d03/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b4f8bc86')) {
      api.createRecord('b4f8bc86', component.options)
    } else {
      api.reload('b4f8bc86', component.options)
    }
    module.hot.accept("./app.vue?vue&type=template&id=b4f8bc86&", function () {
      api.rerender('b4f8bc86', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/course/app.vue"
export default component.exports