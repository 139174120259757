var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "number-wap" },
    [
      _c("div", { staticClass: "text text-cheng" }),
      _vm._v(" "),
      _vm._l(_vm.numbers, function(i, index) {
        return _c("div", {
          key: index,
          staticClass: "text text-num",
          style: {
            backgroundPosition: "0 " + (-i * 56 - 44) + "px",
            transitionDelay: index * 0.2 + "s"
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }