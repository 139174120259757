import toastVue from '../bong_animation/vk_toast.vue'
import basePopup from '../bong_animation/basePopup.vue' // 基本弹窗
// import ruleExplainPopup from '../../../pages/common/popUp/ruleExplain_Popup.vue' // 秘籍_规则说明弹窗
// 新增弹窗组件
// import allPopup from '../../../pages/common/popUp/allPopup/allPopup.vue'
export default {
  install (Vue, options) {
    // 全局声明基本弹窗数据和规则说明弹窗
    Vue.prototype.$basePopup = basePopup
    // Vue.prototype.$ruleExplainPopup = ruleExplainPopup
    // Vue.prototype.$allPopup = allPopup
    // Loading显示与隐藏的实例方法
    Vue.prototype.$LoadingShow = () => {
      let load = document.getElementById('learningLoading')
      let status = load ? load.style.display : ''
      if (load && status === 'none') load.style.display = 'block'
    }
    Vue.prototype.$LoadingClose = () => {
      let load = document.getElementById('learningLoading')
      let status = load ? load.style.display : ''
      if (load && status === 'block') load.style.display = 'none'
    }
    // toast实例方法
    let ToastTemplate = Vue.extend(toastVue)
    Vue.prototype.$showToast = (message, isHappy, time) => {
      var tpl = new ToastTemplate({
        propsData: {
          message: message,
          isHappy: isHappy
        }
      }).$mount().$el

      document.body.appendChild(tpl)
      setTimeout(() => {
        document.body.removeChild(tpl)
      }, time || 2500)
    }
    // 弹出组件显示与隐藏实例方法
    let Tpl = null; let vm = null; let el = null; let parent = null // 父节点
    Vue.prototype.$modelShow = (config) => { // 准备废弃,替换新的$modalShow
      Tpl = Vue.extend(config.model)
      console.warn('$modelShow实例方法即将废弃，请替换新提供的$modalShow方法')
      window.sa.track('page_click_viptrack', {'event_id': 'web_learning_default_modelshow_abamdon'})
      if (vm == null) {
        vm = new Tpl({
          propsData: {
            config: config
          }
        })
        el = vm.$mount().$el
        // 有指定父节点就插入到指定位置，如果没有指定就放到body下
        parent = config.$parent || document.body
        parent.appendChild(el)
      }
    }
    Vue.prototype.$modalShow = (config) => {
      Tpl = Vue.extend(config.model)
      if (vm == null) {
        vm = new Tpl({
          propsData: config
        })
        el = vm.$mount().$el
        // 有指定父节点就插入到指定位置，如果没有指定就放到body下
        parent = config.$parent || document.body
        parent.appendChild(el)
      }
    }
    Vue.prototype.$modelClose = () => {
      !!el && parent.contains(el) && parent.removeChild(el)
      parent = null; vm = null; Tpl = null; el = null // 销毁不必要的变量
    }
  }
}
