<template>
  <div class="get-reward">
    <div class="mask"></div>
    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" >
      <div class="base-reward">
        <div class="aniAward">
          <img class="star-an" v-for="(star,index) in stars" :src="iconUrl ? iconUrl : awardIconMap[type]" :style="star" :class="isUpgrade ? 'medal-an' : ''" :key="index" />
        </div>
        <div class="content">
          <img class="light" :src="type ? lightMap[type]: lightMap['STAR']" v-show="showRewards" />
          <div class="spot" :class="type" v-show="showRewards">
            <div class="award-title" v-if="isUpgrade">
              {{title}}
            </div>
            <div class="sub-title" v-if="isUpgrade">
              {{subTitle}}
            </div>
          </div>
          <div class="circle-cont" :class="type">
            <div class="circle" :class="'circle-'+ i" :key="i" v-for="i in 5"></div>
          </div>
          <div class="number" v-if="number && number!==null && !isUpgrade">
            <number class="number-text" ref="numberText" :number="impelNumber"></number>
          </div>
          <div class="rewards" :class="isUpgrade ? 'isUpgrade-rewards' : ''" v-show="showRewards">
            <img class="award-icon" :src="iconUrl ? iconUrl : awardIconMap[type]" ref="award" :class="isUpgrade ? 'medal-an' : ''" />
          </div>
          <div class="medal-icon-show" v-if="isUpgrade && showRewards">
            <img class="award-icon" :src="subIconUrl" />
            <span class="addAward"> X{{number}}</span>
          </div>
          <div class="desc-text" v-if="desc && showRewards" :class="isUpgrade ? 'text-font': ''"> {{desc}}</div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import number from './vk_number_new'
import awardSound from '../../assets/img/bong_animation/bong/award.mp3'
export default {
  data: function () {
    return {
      impelNumber: '0',
      stars: [],
      awardIconMap: {
        'EGREETING_CARD': require('../../assets/img/bong_animation/bong/heka_icon.png'),
        'EGGSHELL': require('../../assets/img/bong_animation/bong/energy_stone.png'),
        'STAR': require('../../assets/img/bong_animation/bong/task_star_icon.png'),
        'PET_PROP': require('../../assets/img/bong_animation/bong/daoju_icon.png'),
        'VOUCHER': require('../../assets/img/bong_animation/bong/voucher.png')
      },
      lightMap: {
        'EGREETING_CARD': require('../../assets/img/bong_animation/bong/common_light.png'),
        'EGGSHELL': require('../../assets/img/bong_animation/bong/stone_light@2x.png'),
        'STAR': require('../../assets/img/bong_animation/bong/star_light.png'),
        'PET_PROP': require('../../assets/img/bong_animation/bong/common_light.png'),
        'VOUCHER': require('../../assets/img/bong_animation/bong/common_light.png')
      },
      awardTop: 0,
      awardLeft: 0,
      showRewards: true
    }
  },
  props: ['number', 'closeDialog', 'type', 'isIndex', 'location', 'desc', 'iconUrl', 'title', 'subTitle', 'isUpgrade', 'subIconUrl'],
  components: {
    number
  },
  computed: {
    typeObj () {
      let obj = {right: '', top: '', left: ''}
      if ((this.type === 'STAR' || this.type === 'EGGSHELL') && this.location) {
        obj.left = this.location.left + 'px'
        obj.top = '20px'
      }

      return obj
    }
  },
  mounted () {
    this.getNumber()
    let audio = new Audio(awardSound)
    setTimeout(() => {
      audio.play()
    }, 1000)
  },
  methods: {
    getNumber () {
      this.impelNumber = this.number
      this.isShow = true
      this.$nextTick(() => {
        let top = this.$refs.award.getBoundingClientRect().top
        let left = this.$refs.award.getBoundingClientRect().left
        // let right = this.$refs.award.getBoundingClientRect().right - 100
        this.stars = []
        let len = 5
        // 是否首页勋章升级
        this.isUpgrade && (len = 1)
        for (var i = 0; i < len; i++) {
          if ((this.type === 'STAR' || this.type === 'EGGSHELL')) {
            this.stars.push({
              top: top + 'px',
              left: left + 'px',
              opacity: 1
            })
          } else {
            this.stars.push({
              top: top + 'px',
              right: this.$refs.award.getBoundingClientRect().left + 'px',
              opacity: 1
            })
          }
        }
      })
      setTimeout(() => {
        this.hide(this.type)
      }, 2500)
    },
    hide (awardType) {
      this.$nextTick(() => {
        if (this.location) {
          setTimeout(() => {
            let len = 5
            // 是否首页勋章升级
            this.isUpgrade && (len = 1)
            this.stars = []
            for (var i = 0; i < len; i++) {
              // 由大到小scale影响了位置
              if (this.isUpgrade) {
                let clientRect = this.$refs.award.getBoundingClientRect()
                this.stars.push({
                  top: this.typeObj.top.split('px')[0] - (clientRect.height / 2) + (clientRect.height / 2) * 0.2 + 10 + 'px',
                  left: this.typeObj.left.split('px')[0] - (clientRect.width / 2) + (clientRect.width / 2) * 0.2 + 10 + 'px',
                  right: this.typeObj.right,
                  transform: 'scale(0.2, 0.2)',
                  transformAnimation: 'ease-out',
                  opacity: 0,
                  transitionDelay: i * 0.1 + 's'
                })
                this.showRewards = false
              } else {
                this.stars.push({
                  top: this.typeObj.top,
                  left: this.typeObj.left,
                  right: this.typeObj.right,
                  transform: 'scale(0.2, 0.2)',
                  transformAnimation: 'ease-out',
                  opacity: 1,
                  transitionDelay: i * 0.1 + 's'
                })
              }
            }
          }, 200)
        }
        setTimeout(() => {
          // 重置回true
          if (this.isUpgrade) {
            this.showRewards = true
          }
          this.closeDialog()
        }, 700)
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
@import '../../assets/css/mixs.styl'
  .medal-an
    width: 240px !important
    height: 240px !important
    color: #fff
  .get-reward
    wh(100%)
    min-height: 600px
    position: absolute
    top: 0
    z-index: 999999

    overflow: hidden
    .mask
      background: rgba(0,0,0,0.7);
      wh(100%)
      position: absolute;
      z-index: 1;
      animation opacity 0.3s infinite 1
    .base-reward
      wh(100%)
      position: relative;
      z-index: 10;
      .star-an
        position: fixed
        wh(100px)
        /* margin: auto; */
        z-index: 10;
        transform-origin: 50% 50%
        transition: all 0.3s ease
        // opacity 0
      .content
        wh(572px,567px)
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        .light
          wh(572px,567px)
          // background-image: url('../../assets/img/common/light@2x.png');
          // background-size 100% 100%
          // -webkit-animation: rotate 5s linear infinite;
          // animation: rotate 10s linear infinite;
          animation:zoom .3s cubic-bezier(.39,1.51,.79,1.23), rotate 5s linear .5s infinite;
        .spot
          wh(354px,356px)
          position: absolute;
          top: 63px;
          left: 104px;
          background-image: url("../../assets/img/bong_animation/bong/award_default.png")
          background-size: 100% 100%;
          animation: zoom .3s
          animation-timing-function: cubic-bezier(0,2,0,0)
          &.STAR
            wh(558px,374px)
            left: 0;
            background-image: url('../../assets/img/bong_animation/bong/spot_small_yellow.png');
          &.EGGSHELL
            wh(558px,374px)
            left: 0;
            background-image: url('../../assets/img/bong_animation/bong/spot_small.png');
          .award-title
            // margin-top: 20px
            height: 45px
            line-height: 45px
            font-size: 32px
            text-align: center
            font-family:PingFangSC-Semibold,PingFang SC
            font-weight: 600
            color: #ff
          .sub-title
            margin-top: 12px
            font-size: 24px
            font-weight: 600
            text-align: center
            color: #fff
            margin-bottom 20px
        .circle-cont
          animation: zoom .1s linear 0.1s
          &.STAR
            .circle
              box-shadow:none !important
              &.circle-1,&.circle-2,&.circle-3
                wh(42px,42px)
                background:rgba(248,231,28,.4)
              &.circle-4,&.circle-5
                wh(42px,42px)
                background:rgba(248,231,28,.6)
          &.EGGSHELL
            .circle
              box-shadow:none !important
              &.circle-1,&.circle-2,&.circle-3
                wh(42px,42px)
                background:rgba(121,255,201,.4)
              &.circle-4,&.circle-5
                wh(42px,42px)
                background:rgba(0,172,203,.6)
          &.STAR, &.EGGSHELL
            .circle
              &.circle-1
                top 33px
                left 173px
              &.circle-2
                top 236px
                left 75px
              &.circle-3
                top 400px
                left 125px
              &.circle-4
                top 392px
                left 375px
              &.circle-5
                top 99px
                left 440px
          .circle
            wh(42px,42px)
            position absolute
            top 33px
            left 173px
            border-radius 50%
            &.circle-1
              top 144px
              left 28px
              wh(26px)
              background:rgba(249,195,234,0.81);
              box-shadow:0px 0px 11px 0px rgba(225,83,255,0.75);
              animation: opacityone 1.6s linear 0.2s infinite
            &.circle-2
              top 344px
              left 17px
              wh(67px)
              background:rgba(91,198,255,0.83);
              box-shadow:0px 0px 11px 0px rgba(0,210,255,1);
              animation: opacityTwo 1.4s linear 0.2s infinite
            &.circle-3
              top 371px
              left 380px
              wh(63px)
              background:rgba(154,102,255,0.59);
              box-shadow:0px 0px 11px 0px rgba(172,90,252,0.56);
              animation: opacityThree 1s linear 0.2s infinite
            &.circle-4
              top 141px
              left 501px
              wh(22px)
              background:rgba(249,231,195,1);
              box-shadow:0px 0px 4px 0px rgba(255,247,78,0.66);
              animation: opacityFour 1.6s linear 0.2s infinite
            &.circle-5
              top 59px
              left 421px
              wh(45px)
              background:rgba(249,195,234,0.72);
              box-shadow:0px 0px 11px 0px rgba(225,83,255,0.75);
              animation: opacityFive 1.8s linear 0.2s infinite
        .number
          wh(572px, 48px)
          position: absolute;
          top: 120px;
          display: flex;
          align-items: center;
          justify-content: center;
        .medal-icon-show
          position: absolute
          // bottom: 90px
          bottom: 120px
          left: 50%;
          transform: translate(-50%, 0);
          // left 226px
          // left 160px
          img
            float left
            width 48px
            height 48px
          .addAward
            float left
            margin-top 16px
            display inline-block
            font-size 28px
            color #fff
        .rewards
          wh(572px, 100px)
          ab-vcenter()
          display: flex
          justify-content: space-around
          align-items: center
          flex-wrap: wrap
          &.isUpgrade-rewards
            wh(572px, 240px)
          img
            wh(100px)
            animation: zoom 0.3s cubic-bezier(.39,1.51,.79,1.23) .05s
          .reward
            wh(200px)
            &.reward-pop
              transform: scale(0)
              animation: zoom .3s cubic-bezier(.39,1.51,.79,1.23) forwards
            &.reward-vanish
              transform: scale(1)
              animation: item-vanish .3s ease-in forwards
        .desc-text
          height: 90px;
          line-height 90px
          font-size: 24px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: center;
          color: #ffffff;
          position absolute
          width 100%
          bottom 43px
        .text-font
          height: auto;
          line-height: 1;
          bottom: 88px;
          color: #fff
          font-size 20px
  @keyframes zoom
    0%
      transform: scale(0)
    100%
      transform: scale(1)
  @keyframes rotate
    0%
      transform: rotateZ(0)
    100%
      transform: rotateZ(360deg)
  @keyframes twinkling
    0%
      opacity: 0
    25%
      opacity: 1
    75%
      opacity: 0
    100%
      opacity: 1
  @keyframes twinkles
    0%
      transform: scale(0)
      opacity 0
    50%
      opacity 1
    100%
      transform: scale(1)
      opacity 0
  @keyframes opacityone
    0%
      opacity 1
    50%
      opacity 0.2
    100%
      opacity 1
  @keyframes opacityTwo
    0%
      opacity 1
    50%
      opacity 0.2
    100%
      opacity 1
  @keyframes opacityThree
    0%
      opacity 1
    50%
      opacity 0
    100%
      opacity 1
  @keyframes opacityFour
    0%
      opacity 1
    50%
      opacity 0.4
    100%
      opacity 1
  @keyframes opacityFive
    0%
      opacity 1
    50%
      opacity 0.4
    100%
      opacity 1
  @keyframes opacity
    0%
      opacity 0
    100%
      opacity 1
</style>
