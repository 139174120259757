<template>
  <div class="vk-button">
    <div class="btn" :class="[type.toLowerCase()]" @click.stop="goToAction">
      <div class="btn-bg" v-if="havbg"></div>
      <div class="cont">
        <template>
          <div v-for="(item,index) in text" :key="index">
            <div class="img">
              <img src="../../assets/img/course/icon_ring@2x.png" alt="" class="tip-icon shining" v-if="item.contentType=='IMAGE'">
            </div>
            <span class="text" v-if="item.contentType=='TEXT'">{{item.content}}</span>
          </div>
        </template>

      </div>
    </div>

  </div>
</template>
<script>
export default {
  data () {
    return {

    }
  },
  props: {
    type: {
      require: false,
      type: String,
      default: 'SHOW'
    },
    buttonType: {
      require: false,
      type: String,
      default: ''
    },
    text: {
      require: false,
      type: Array
    },
    havbg: {
      require: false,
      type: Boolean,
      default: false
    }
  },
  methods: {
    goToAction () {
      if (typeof Vm.$playSound === 'function') {
        Vm.$playSound("click")
      }
      this.$emit('goto')
    }
  }
}
</script>
<style lang="stylus" scoped>
  .vk-button
    .btn
      width 180px
      height 60px
      background: linear-gradient(to bottom right,#ff8519,#ff661a);
      border-radius 30px
      display flex
      align-items center
      justify-content center
      cursor pointer
      position relative
      overflow: hidden;
      &.show
        transition: 0.2s
        cursor: pointer
        background: linear-gradient(to bottom right,#ff8519,#ff661a);
        &:hover
          transform: translateZ(0) scale(1.05)
        &:active
          transform: translateZ(0) scale(0.95)
      &.gray
        cursor: not-allowed
        background-color: rgba(190,184,204,1)
      &.gray_click
        cursor: not-allowed
        background-color: rgba(190,184,204,1)
      .btn-bg
        width: 100px;
        height: 30px;
        background: #ff5a08;
        border-radius: 100px 0px 100px 0px;
        position: absolute;
        right: 0;
        bottom: 0;
      &.gray, &.gray_click
        cursor: not-allowed
        background: #cccccc
        .btn-bg
          background: #cccccc
      .cont
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .img
          transform rotate(30deg)
          margin-top: 3px
        .tip-icon
          width 32px
          height 32px
          margin-right 8px
          animation: ring 1.5s linear infinite;
          display block
        .text
          font-size: 24px
          font-family: PingFangSC, PingFangSC-Semibold
          font-weight: 600
          color: #ffffff
          transform: translate3d(0,0,0)
  @keyframes ring
      0%,66%
        -webkit-transform:rotate(0deg)
      88%
        -webkit-transform:rotate(-45deg)

</style>
