<template>
  <div class="toast-con">
    <div class="toast animated bounceIn">
      <div class="toast-bg"></div>
      <div class="toast-board"></div>
      <div class="content" v-html="message"></div>
    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {}
  },
  props: ['message', 'isHappy'],
  components: {},
  computed: {},
  mounted () {
  },
  methods: {}
}
</script>
<style lang="stylus" scoped>
.toast-con
  width: 100%
  height: 100%
  position: fixed
  top: 0
  left: 0
  z-index: 2000
.black-mask
  width: 100%
  height: 100%
  position: absolute
  top: 0
  left: 0
  background-color: rgba(0, 0, 0, 0.6)
.toast
  position: absolute
  top: 50%
  left: 50%
  width: 288px
  min-height: 68px
  margin-top: -76px
  margin-left: -144px
  font-size: 16px
  color: #fff
  text-align: center
  box-sizing: border-box
.toast-bg
  position: absolute
  top: 0px
  left: 0px
  z-index: -1
  width: 100%
  height: 100%
  border-radius: 20px
  background: linear-gradient(90deg, #dcf, rgba(255,255,255,0.1) 30%, rgba(255,255,255,0.1) 70%, #dcf)
.toast-board
  position: absolute
  top: 4px
  left: 4px
  z-index: 1
  width: calc(100% - 8px)
  height: calc(100% - 8px)
  border-radius: 20px
  background-color: #402A6B
  overflow: hidden
.content
  position: relative
  z-index: 3
  width: 80%
  line-height: 24px
  padding: 22px 0
  display: inline-block
  vertical-align: middle
</style>
