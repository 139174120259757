const isSupportWebp = !![].map && document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0

function getProvider (url) {
  const providers = [
    {
      domain: 'media.vipkidstatic.com',
      provider: 'aliyun'
    },
    {
      domain: 'resource.vipkid.com.cn',
      provider: 'aliyun'
    },
    {
      domain: 's.vipkidstatic.com',
      provider: 'aliyun'
    },
    {
      domain: 'img.vipkidstatic.com',
      provider: 'aliyun'
    },
    {
      domain: 's.vipkidresource.com',
      provider: 'tengxunyun'
    },
    {
      domain: 'img.vipkidresource.com',
      provider: 'tengxunyun'
    },
    {
      domain: 'media.vipkidresource.com',
      provider: 'tengxunyun'
    }

  ]
  let i = providers.length
  while (i--) {
    if (url.indexOf(providers[i].domain) !== -1) return providers[i].provider
  }
}
/**
* @desc 图片路径处理 缩放 转webp
* @param url: String !scale: Number 默认不缩放
* @return 添加了优化参数的url
*/
function optimizeImgUrl (url, scale = 100) {
  let optimizeUrl = url
  const provider = getProvider(url)
  switch (provider) {
    case 'tengxunyun':
      optimizeUrl = `${url}?imageMogr2/${isSupportWebp ? '/format,webp' : ''}`
      break
    case 'aliyun':
      optimizeUrl = `${url}?x-oss-process=image/resize,p_${scale}${isSupportWebp ? '/format,webp' : ''}`
  }
  return optimizeUrl
}
let bind =
      window.addEventListener !== undefined
        ? 'addEventListener'
        : 'attachEvent'
let unbind =
        window.removeEventListener !== undefined
          ? 'removeEventListener'
          : 'detachEvent'
let wheel =
      window.onwheel !== undefined
        ? 'wheel'
        : window.onmousewheel !== undefined
          ? 'mousewheel'
          : window.attachEvent
            ? 'onmousewheel'
            : 'DOMMouseScroll'
let wheelElement = null
function horizontalWheel (eve, el) {
  el.scrollLeft +=
        eve.deltaY !== undefined
          ? eve.deltaY
          : eve.detail !== undefined && eve.detail !== 0
            ? eve.detail
            : -eve.wheelDelta
}
function horwheelEvent (eve) {
  eve = eve || window.eve
  // if (eve.preventDefault) {
  //     eve.preventDefault();
  // }
  horizontalWheel(eve, wheelElement)
  return false
}

/**
 * horwheel
 */
function horwheel (el) {
  if (el === undefined) {
    return
  }
  wheelElement = el
  document[bind](
    wheel,
    horwheelEvent,
    false
  )
}
function unbindHorwheel (el) {
  if (el === undefined) {
    return
  }
  document[unbind](
    wheel,
    horwheelEvent,
    false
  )
}
export {
  optimizeImgUrl,
  horwheel,
  unbindHorwheel
}
