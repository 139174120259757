import axios from 'axios'
import cookies from 'cookies-js'
function goToClassRoomInDragon(onlineClassId, isOpenCourse, callBackFun) {
  var dragonClientUA = navigator.userAgent.match(/vipkid\/\d+(\.\d+)*/i)
  //灰度
  axios.get('/rest/vanguard/api/service/client/classroom/getRoomUrl', {
    params: {
      onlineClassId: onlineClassId,
      studentId: cookies.get("studentId"),
      version: dragonClientUA ? dragonClientUA[0].split('/')[1] : '0'
    }
  }).then((res) => {
    try {
      if (res.data.data.roomType == 'NA') {
        window.__bridge.openClass(onlineClassId, res.data.data.supplierCode)
        if (callBackFun) {
          callBackFun()
        }
      } else {
        flashClassRoom(isOpenCourse, callBackFun, onlineClassId)
      }
    } catch (e) {
      flashClassRoom(isOpenCourse, callBackFun, onlineClassId)
    }
  }).catch((err) => {
    flashClassRoom(isOpenCourse, callBackFun, onlineClassId)
  })
}

function isOnDragonClient() {
  if (navigator.userAgent.toLowerCase().match(/vipkid/i)) {
    return true
  } else {
    return false
  }
}
function flashClassRoom(isOpenCourse, callBack, onlineClassId) {
  if (isOpenCourse) {
    window.location.href = `/router/www/openclass/student/onlineclass?onlineClassId=${onlineClassId}`
  } else {
    window.location.href = `/router/learning/classroom/major?onlineClassId=${onlineClassId}`
  }
  if (callBack) {
    callBack()
  }
}

export default {
  isOnDragonClient,
  goToClassRoomInDragon
}
