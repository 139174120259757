var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "common-class",
      class: [
        _vm.btnColorClass[_vm.btnColorType],
        _vm.getBtnSize(_vm.btnSize),
        _vm.clickAnimation ? "clickAnimation" : ""
      ],
      style: { minWidth: _vm.minWidth + "px", width: _vm.width + "px" },
      on: { click: _vm.buttonClick }
    },
    [
      _vm._l(_vm.text, function(params, index) {
        return [
          params.contentType === "TEXT"
            ? _c("span", { key: index }, [_vm._v(_vm._s(params.content))])
            : _vm._e(),
          _vm._v(" "),
          params.contentType === "IMAGE"
            ? _c("img", {
                key: index,
                staticClass: "icon-img",
                attrs: { src: params.content }
              })
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }