import axios from 'oaxios'
const cookies = require('cookies-js')
axios.interceptors.request.use(function (config) {
  let isLcPermissionCheck
  if(config.method === 'get' && config.params) {
    isLcPermissionCheck = config.params.isLcPermissionCheck
    delete config.params.isLcPermissionCheck
  }
  let cookieAuth = cookies.get('Authorization')
  let cookie_session = cookies.get('vk_session_id')
  if (cookie_session) {
    config.headers['vk-session-id'] = cookie_session
  }
  if(isLcPermissionCheck === false){ //不需要权限校验
    return config
  }
  if (!config.headers.hasOwnProperty('Authorization') && cookieAuth) {
    config.headers['Authorization'] = cookieAuth.replace(/\"/g, '')
    if (config.params) {
      config.params.v = Math.random()
    }
  } else if (navigator.userAgent.toLowerCase().match(/vipkid/i)) {
    sa.track('page_click_viptrack', {
      'event_id': 'pcapp_request_login',
      'event_content': 'pcapp端request阶段检测无鉴权字段，跳转登陆'
    })
    window.__bridge.logout() //小恐龙退出登录
  } else {
    sa.track('page_click_viptrack', {
      'event_id': 'other_request_login',
      'event_content': '其他端request阶段检测无鉴权字段，跳转登陆'
    })
    window.location.href = 'https://' + window.location.host.replace(/lc/, 'www') + '/login?redirect_uri='+encodeURIComponent(window.location.href)
  }
  return config
},err=>{ // ----- by luomingzhong
  return Promise.reject(err)
})

axios.interceptors.response.use(response => {
  if (response.headers['vk-mvp-url']) {
    location.href = response.headers['vk-mvp-url']
  }
  return response
}, err => {
  let res = err.response
  if (res.status === 401) {
    const currentUrl = location.href
    if(currentUrl.includes('/dgl/bee-')){
      //当是蜂校的路径的时候。；
    } else if (navigator.userAgent.toLowerCase().match(/vipkid/i)) {
      sa.track('page_click_viptrack', {
        'event_id': 'pcapp_response_login',
        'event_content': 'pcapp端response阶段检测无鉴权字段，跳转登陆'
      })
      window.__bridge.logout() //小恐龙退出登录
    } else {
      sa.track('page_click_viptrack', {
        'event_id': 'other_response_login',
        'event_content': '其他端response阶段检测无鉴权字段，跳转登陆'
      })
      window.location.href = 'https://' + window.location.host.replace(/lc/, 'www') + '/login?redirect_uri='+encodeURIComponent(window.location.href)
    }
  }
  if (err.response.data) {
    //记录错误日志
    //新库
    sa.track('page_click_viptrack', {
      'event_id':'web_learning_request_error_message',
      'request': err.config.url,
      'str1': JSON.stringify(err.response.data)
    })
  }
  return Promise.reject(err)
})

export default axios
