<template >
  <component :is="popUpsType" v-bind="configData"></component>
</template>

<script>
import bong from '../bong_animation/bong_animation'
import basePopup from '../bong_animation/basePopup'
export default {
  props: {
    popUpsType: '',
    config: {}
  },
  components: {
    bong,
    basePopup
  },
  data () {
    return {
    }
  },
  computed: {
    configData () {
      let formtatConfig = this.config
      let findArray = ['COMMONALERT', 'SPECIALALERT']
      if (findArray.indexOf(this.config.type) !== -1) {
        formtatConfig = this.formtatConfig(this.config)
      }
      return formtatConfig
    }
  },
  methods: {
    // 对外部数据统一处理 转换为基础弹窗组件统一数据
    formtatConfig (config) {
      let configData = {
        ...config,
        contentText: config.tipContents || '',
        isShowCloseBtn: !!config.closeButtonPos,
        popupType: config.popupSize ? config.popupSize.toLowerCase() : 'middle',
        tipsText: config.notShowText,
        isShowCheckbox: !!config.notShowText
      }
      return configData
    }
  }
}

</script>
