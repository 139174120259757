<template>
  <div class="net-error">
    <img class="net-img" :src="_imgSrc" :style="{width: `${imgWidth}px`, height: `${imgHeight}px`}" />
    <slot>
      <div class="net-text"> OPPS！空间站出错了，刷新试试～</div>
    </slot>
    <base-button-component  :text="text" btnColorType="mainSelect" btnSize="large" width="200" @btnClick="refreshEvent"></base-button-component>
  </div>
</template>

<script>
import baseButtonComponent from './common_button'
export default {
  props: {
    type: {
      type: String,
      default: 'default'
    },
    imgWidth: {
      type: [Number, String],
      default: 300
    },
    imgHeight: {
      type: [Number, String],
      default: 300
    },
    imgSrc: {
      type: String
    },
    refreshEvent: {
      type: Function,
      default: () => {
      }
    }
  },
  computed: {
    _imgSrc: function () {
      return this.imgSrc || require('../../assets/img/common/Default-error.png')
    }
  },
  data () {
    return {
      text: [
        {
          contentType: 'TEXT',
          content: '刷新重试'
        }
      ]
    }
  },
  created () {},
  components: {
    baseButtonComponent
  }
}

</script>
<style lang="stylus" scoped>
  .net-error
    text-align: center
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%,-50%)
    display: flex
    flex-direction: column
    align-items: center
    .net-text
      font-size: 20px
      font-family: PingFangSC, PingFangSC-Regular
      font-weight: 400
      text-align: center
      color: #ffffff
      white-space: nowrap
      margin-bottom: 20px
</style>
