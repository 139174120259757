import axios from 'axios'
import formurlencoded from 'form-urlencoded'
import cookies from 'cookies-js'
import hybridPad from './hybridPad'
const prefix = '/rest/learninggw/api/pc'
const successCode = 200

const studentId = cookies.get('studentId')
const timeout = 15000
let deviceSource = {
  'PCAPP': '2',
  'IPAD': '1',
  'ANDROID': '3',
  'WEB': '0'
}
let source = deviceSource[hybridPad.deviceType()]
let vn = hybridPad.getAppVersion()

// 网络或接口异常数据模版
export const errorTpl = {
  status: false,
  msg: '网络或数据接口异常'
}
/**
* @desc 接口响应统一处理
* @param res 接口响应
* @param convert 响应成功的数据处理回调 非必传
* @return 返回数据 成功 status = true 否者 status = false，返回 msg
*/
function resHandle (res, convert = data => data) {
  const resData = res.data
  if (resData.code === successCode) {
    return {
      status: true,
      data: convert(resData.data)
    }
  }
  return {
    status: false,
    msg: resData.msg || '数据接口未知错误'
  }
}

/**
* @desc 获取路径数据
* @param param Object {pathId}
* @param convert 接口数据处理方法
* @return 路径所有数据
*/
export function getCourseCardsData (param, convert) {
  const params = {
    studentId,
    source,
    vn,
    ...param
  }
  return axios.get(`${prefix}/homepage/v4/getCourseCards`, {params}).then(res => {
  // return axios.get('https://vk-api.vipkid.com.cn/mock/2286/rest/learninggw/api/pc/homepage/v4/getCourseCards', {params}).then(res => {
    return resHandle(res, convert)
  }).catch(e => {
    return errorTpl // return errorTpl
  })
}

/**
* @desc 领取奖励物
* @param param Object {serialNumber}
* @return 领取结果
*/
export function receiveReward (param) {
  const params = {
    studentId,
    ...param
  }
  return axios.post(`/rest/learninggw/api/point/activity/newHomePage/receiveCommonActivityAward`, formurlencoded(params)).then(res => {
    return resHandle(res)
  }).catch(() => {
    return errorTpl
  })
}

/**
* @desc 获取弹窗列表
* @param param Object {serialNumber}
* @return 数据列表
*/
export function getPopupList (param) {
  const params = {
    studentId,
    source,
    vn
  }
  let baseUrl = `/rest/learninggw/api/pc/v4/homepage/popupWindows?${formurlencoded(params)}`
  param.notReminders.map(item => {
    baseUrl += `&notReminders=${item}`
  })
  return axios.get(baseUrl, {timeout}).then(res => {
    return resHandle(res)
  }).catch(() => {
    return errorTpl
  })
}
/**
* @desc 个人信息接口
* @param param
* @return 个人信息
*/
export function getStudentBaseInfo () {
  const params = {
    studentId
  }
  return axios.get(`${prefix}/student/getStudentBaseInfo`, {params, timeout}).then(res => {
    return resHandle(res)
  }).catch(() => {
    return errorTpl
  })
}
/**
* @desc 首页右上角接口
* @param param
* @return 入口列表
*/
export function getRightTopList () {
  const params = {
    studentId,
    source,
    vn
  }
  return axios.get(`${prefix}/v4/homepage/getRightTopList`, {params, timeout}).then(res => {
    return resHandle(res)
  }).catch(e => {
    return errorTpl // return errorTpl
  })
}
/**
* @desc 第二屏入口
* @param param
* @return 数据列表
*/
export function getExtendedLearningData (param) {
  const params = {
    studentId,
    source,
    vn,
    ...param
  }
  return axios.get(`${prefix}/v4/homepage/homeSecond`, {params, timeout}).then(res => {
    return resHandle(res)
  }).catch(e => {
    return errorTpl // return errorTpl
  })
}
/**
 * 新手引导接口
*/
export function haveClassInFifteen () {
  const params = {
    studentId,
    source,
    vn
  }
  return axios.get(`${prefix}/v4/homepage/haveClassInFifteen`, {params, timeout}).then(res => {
    return resHandle(res)
  }).catch(e => {
    return errorTpl // return errorTpl
  })
}
/**
 * 判断是否弹窗
*/
export function haveExpireReplayCheck (param) {
  const params = {
    studentId,
    ...param
  }
  return axios.get(`${prefix}/homepage/expireReplayCheck`, {params}).then(res => {
    return resHandle(res)
  }).catch(e => {
    return errorTpl // return errorTpl
  })
}

export default {
  getCourseCardsData,
  receiveReward,
  getPopupList,
  getStudentBaseInfo,
  getRightTopList,
  getExtendedLearningData,
  haveClassInFifteen,
  haveExpireReplayCheck
}
