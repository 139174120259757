
import utils from './_untils'

/**
 * 格式化链接
 * @params $linkUrl 后台下发的链接
 */
function handlelink (linkUrl) {
  if (!linkUrl) return
  // 发生跳转
  // debugger
  let goRoute = linkUrl.split('url=')[1]
  if (linkUrl.indexOf('system/redirect_relative?url') !== -1) { // 相对路径
    VK.push(goRoute)
  } else if (linkUrl.indexOf('system/redirect?url') !== -1) { // 绝对路径
    const _type = utils.isLcSubmodules(goRoute)
    const _url = formetUrl(goRoute, _type)
    if (_type) {
      VK.push(_url)
    } else {
      window.location.href = addParamsIntoURL(goRoute, {back_url: location.href})
    }
  } else if (linkUrl.indexOf('system/open?url') !== -1) {
    window.open(goRoute)
  }
}
/**
 * 格式化链接
 * @params $url 要跳转的链接
 * @returns String 经过格式化的链接
 */
function formetUrl ($url, $type) {
  if ($type) {
    // const _path = $url.replace(/http(s)?:\/\/([^/].*?)lc(.*?).vipkid(-qa)?.com.cn/, '')
    const _path = $url.split('.com.cn')[1]
    return _path
  } else {
    return $url + '?back_url=' + window.location.href
  }
}
/**
 * backurl
 * @url 第三方链接
 * @params 参数,back_url
*/
function addParamsIntoURL (url = '', params = {}) {
  var hash = url.replace(/^[^#]+(#.+)?$/, '$1')
  var paramStr = ''
  Object.keys(params).forEach(function (key) {
    paramStr += `&${key}=${encodeURIComponent(params[key])}`
  })
  url = url.replace(hash, '')
  url += /\?/.test(url) ? paramStr : `?${paramStr}`
  url += hash
  return url
}
export default {
  handlelink
}
