var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialogWarpper" }, [
    _c("div", { staticClass: "vk-dialog-container" }, [
      _c(
        "div",
        {
          staticClass: "dialog-bg",
          class: [_vm.dialogBgSty],
          style: {
            backgroundImage: _vm.backGroundImg ? "url(" + _vm.backGroundImg : ""
          },
          on: {
            click: function($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              return _vm.imgClick($event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "topWarp", class: { bgPosition: _vm.headImage } },
            [
              _vm.mood
                ? _c("div", { staticClass: "moodImg", class: _vm.moodImgSty })
                : _vm._e(),
              _vm._v(" "),
              _vm.headImage
                ? _c("div", {
                    staticClass: "moodImg bgImg",
                    style: { backgroundImage: "url(" + _vm.headImage + ")" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.title && !_vm.headImage
                ? _c("div", {
                    staticClass: "dialog-title",
                    class: { noMood: !_vm.mood },
                    domProps: { textContent: _vm._s(_vm.title) }
                  })
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _vm.isUseSlot
            ? _c(
                "div",
                { staticClass: "slotWarpper", class: _vm.contentSty },
                [_vm._t("default")],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isUseSlot
            ? _c(
                "div",
                { staticClass: "contentWarpper", class: _vm.contentSty },
                [
                  !Array.isArray(_vm.contentText)
                    ? _c("div", {
                        staticClass: "textBox",
                        class: _vm.dialogBgSty,
                        domProps: { innerHTML: _vm._s(_vm.contentText) }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.contentText, function(item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "conent-box" },
                      [
                        Array.isArray(_vm.contentText)
                          ? [
                              item.contentType === "IMAGE"
                                ? _c("img", {
                                    staticClass: "content-img",
                                    attrs: { src: item.content }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              item.contentType === "TEXT"
                                ? _c("div", { staticClass: "content-text" }, [
                                    _vm._v(" " + _vm._s(item.content))
                                  ])
                                : _vm._e()
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  })
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isUseSlot
            ? _c("footer", [
                !Array.isArray(_vm.buttonList)
                  ? _c("div", { staticClass: "button-box" }, [
                      _vm.leftButtonText
                        ? _c(
                            "div",
                            {
                              staticClass: "dialog-btn btn-left",
                              on: { click: _vm.leftBtnEvent }
                            },
                            [_vm._v(" " + _vm._s(_vm.leftButtonText))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "dialog-btn btn-right",
                          on: { click: _vm.rightBtnEvent }
                        },
                        [_vm._v(" " + _vm._s(_vm.rightButtonText))]
                      )
                    ])
                  : _c(
                      "div",
                      { staticClass: "button-box button-flex" },
                      [
                        _vm._l(_vm.buttonList, function(item, index) {
                          return [
                            _c("common-btn", {
                              key: index,
                              attrs: {
                                btnColorType: item.buttonStyle,
                                text: item.text,
                                btnSize: "large",
                                minWidth: _vm.btnMinWidth[_vm.popupType]
                              },
                              on: { btnClick: _vm.btnEvent[index] }
                            })
                          ]
                        })
                      ],
                      2
                    ),
                _vm._v(" "),
                _vm.tipsText && _vm.isShowCheckbox
                  ? _c(
                      "div",
                      {
                        staticClass: "noTip",
                        on: { click: _vm.checkIconClick }
                      },
                      [
                        _c("div", { staticClass: "defaultBtn" }, [
                          _vm.checkIconKey
                            ? _c("div", { staticClass: "checkIcon" })
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "customTip",
                          domProps: { innerHTML: _vm._s(_vm.tipsText) }
                        })
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.tipsText && !_vm.isShowCheckbox
                  ? _c("div", {
                      staticClass: "noTip",
                      domProps: { innerHTML: _vm._s(_vm.tipsText) }
                    })
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isShowCloseBtn
            ? _c("div", {
                staticClass: "closebtn",
                class: _vm.closeButtonPos,
                style: {
                  backgroundImage: _vm.closeButtonImage
                    ? "url(" + _vm.closeButtonImage
                    : ""
                },
                on: { click: _vm.closebtnEvent }
              })
            : _vm._e()
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }