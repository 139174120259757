var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "net-error" },
    [
      _c("img", {
        staticClass: "net-img",
        style: { width: _vm.imgWidth + "px", height: _vm.imgHeight + "px" },
        attrs: { src: _vm._imgSrc }
      }),
      _vm._v(" "),
      _vm._t("default", [
        _c("div", { staticClass: "net-text" }, [
          _vm._v(" OPPS！空间站出错了，刷新试试～")
        ])
      ]),
      _vm._v(" "),
      _c("base-button-component", {
        attrs: {
          text: _vm.text,
          btnColorType: "mainSelect",
          btnSize: "large",
          width: "200"
        },
        on: { btnClick: _vm.refreshEvent }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }