
import utils from '../../utils/_untils'
import dragonUtils from "../../utils/_dragon"
import allPopup from "./allPopup.vue"
import qrPopup from "./qrPopup.vue"
import service from '../../utils/service'
function courseButtonClick({route, onlineClassId}) {
  // 技术打点
  /* eslint-disable */
  sa.track('page_click_viptrack', {
    'event_id': 'web_new_learning_router',
    'str1': route,
    'str2': onlineClassId ? onlineClassId :''
  })
  var reg = RegExp(/vkstudy/)
  if (route.indexOf('system/classRoom?url') !== -1) {  
    if(dragonUtils.isOnDragonClient()){
      const goRoute = route.split('classRoom?url=')[1]
      const pcAppVersion = navigator.userAgent.match(/vipkid\/(\d+(\.\d+)*)/i)[1]
      const supportVer = '3.6.0'
      if (utils.compareVersion(pcAppVersion, supportVer)) {
        if (window.NaBridge && window.NaBridge.openClassV2) {
          window.NaBridge.openClassV2('GROUP', '', goRoute)
          sa.track('page_click_viptrack', {
            'event_id': 'pcapp_openClassV2',
            'str1': pcAppVersion ? pcAppVersion :'',
          })
        } else {
          sa.track('page_click_viptrack', {
            'event_id': 'pcapp_nosupport_openClassV2',
            'str1': pcAppVersion ? pcAppVersion :'',
          })
        }
        
      } else {
        if(window.__bridge){
          window.__bridge.openClass(onlineClassId)
        } else {
          sa.track('page_click_viptrack', {
            'event_id': 'pcapp_nosupport_openClass',
            'str1': pcAppVersion ? pcAppVersion :'',
          })
        }
        
      }
    }
  } else if (route.indexOf('system/disenable') === 0) { // 提示
    Toast(route)
  } else if (route.indexOf('system/redirect_relative?url') !== -1) { // 相对路径
    const goRoute = route.split('redirect_relative?url=')[1]
    window.VK.push({ path: goRoute})
  } else if (route.indexOf('system/redirect?url') !== -1) { // 绝对路径
    const goRoute = route.split('redirect?url=')[1]
    const _type = utils.isLcSubmodules(goRoute)
    const _url = formetUrl(goRoute, _type)
    if (_type) {
      window.VK.push({path: _url})
    } else {
      if (goRoute.indexOf('warrior') !== -1) {
        window.location.href = goRoute
      } else {
        window.location.href = addParamsIntoURL(goRoute, {back_url: location.href})
      }
      
    }
  } else if(route.indexOf('system/open?url') !== -1){
    const roomType = route.split('roomType=')[1]?.split('&')[0]
    if (roomType) {
      service.haveExpireReplayCheck({
        onlineClassId: onlineClassId || route.split('onlineClassId=')[1]?.split('&')[0], 
        roomType
      }).then(res => {
        if(res.status) {
          if (res.data && res.data.needPop) {
            qrCodePopup(res.data)
          } else {
            window.open(route.split('open?url=')[1])
          }
        }
      })
    } else {
      window.open(route.split('open?url=')[1])
    }
  } else if(route.indexOf('system/groupclassroom?url') !== -1) {
    // 双优小组课进教室 低于3.6.0版本不能上课,需要升级客户端
    const goRoute = route.split('groupclassroom?url=')[1]
    // 判断是否是PCAPP
    if(dragonUtils.isOnDragonClient()){
      if(window.NaBridge && window.NaBridge.openClassV2) {
        window.NaBridge.openClassV2('GROUP', onlineClassId, goRoute)
      }
    }
    
  } else if(route.indexOf('system/alert/vkalert') !== -1){
    // 根据不同type 来区分是普通弹框还是特殊弹框
    const dialogData = JSON.parse(decodeURIComponent(route.split('data=')[1]))
    Popup(dialogData,onlineClassId)
      
  } else if(route.match(reg)){
    let url = encodeURIComponent(route)
    utils.dispatchForIpad('vkappbridge://ipadcommon/ipadRouter?route=' + url)
  }
  Vm.$playSound("click")
}
// toast 
function Toast(route){
  pxVm.$showToast(route.split('text=')[1])
}
// 弹框
function Popup(config, onlineClassId){
    let baseObj = {
      model: allPopup,
      popUpsType: 'basePopup',
      config: {
        ...config,
        leftBtnEvent:() =>{
          let route = config.buttonList[0].route
          pxVm.$modelClose()
          /* eslint-disable */
          sa.track('page_click_viptrack', {
            'event_id': 'web_learning_popup_left_button',
            'str1': route,
          })
          courseButtonClick({route,onlineClassId})
        },
        closebtnEvent:() =>{
          /* eslint-disable */
          sa.track('page_click_viptrack', {
            'event_id': 'web_learning_popup_close_button'
          })
          pxVm.$modelClose()
        },
        rightBtnEvent: () => {
          let route = config.buttonList[1].route
          pxVm.$modelClose()
          /* eslint-disable */
          sa.track('page_click_viptrack', {
            'event_id': 'web_learning_popup_right_button',
            'str1': route,
          })
          courseButtonClick({route,onlineClassId})
        } 
      }
    }
    pxVm.$modalShow(baseObj)
}
// 二维码弹框
function qrCodePopup(qrData){
  let baseObj = {
    model: qrPopup,
    popUpsType: 'basePopup',
    config: {
      isShowCloseBtn: true,
      isUseSlot: true,
      closeButtonPos: 'CONTENT',
      type: 'COMMONALERT',
      qrData: qrData,
      closebtnEvent:() =>{
        /* eslint-disable */
        sa.track('page_click_viptrack', {
          'event_id': 'web_learning_popup_close_button'
        })
        pxVm.$modelClose()
      }
    }
  }
  sa.track('page_trigger_viptrack', {
    'event_id': 'study_center_playback_overdue_show'
  })
  pxVm.$modalShow(baseObj)
}
/**
 * 格式化链接
 * @params $url 要跳转的链接
 * @returns String 经过格式化的链接
 */
function formetUrl ($url, $type) {
  if ($type) {
    // const _path = $url.replace(/http(s)?:\/\/([^/].*?)lc(.*?).vipkid(-qa)?.com.cn/, '')
    const _path = $url.split('.com.cn')[1]
    return _path
  } else {
    return $url + '?back_url=' + window.location.href
  }
}
/**
 * backurl
 * @url 第三方链接
 * @params 参数,back_url
*/
function addParamsIntoURL (url = '', params = {}) {
  var hash = url.replace(/^[^#]+(#.+)?$/, '$1')
  var paramStr = ''
  Object.keys(params).forEach(function (key) {
    paramStr += `&${key}=${encodeURIComponent(params[key])}`
  })
  url = url.replace(hash, '')
  url += /\?/.test(url) ? paramStr : `?${paramStr}`
  url += hash
  return url
}

export default {
  courseButtonClick
}
