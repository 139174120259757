    
<template >
  <component :is="popUpsType" v-bind="configData">
    <slot>
      <div class="modal-content">
        <div class="title">{{configData.qrData.displayText}}</div>
        <img class="qr-code" :src="configData.qrData.qrCodeUrl">
      </div>
    </slot>
  </component>
</template>

<script>
import bong from '../bong_animation/bong_animation'
import basePopup from '../bong_animation/basePopup'
export default {
  props: {
    popUpsType: '',
    config: {}
  },
  components: {
    bong,
    basePopup
  },
  data () {
    return {}
  },
  computed: {
    configData () {
      let formtatConfig = this.config
      let findArray = ['COMMONALERT', 'SPECIALALERT']
      if (findArray.indexOf(this.config.type) !== -1) {
        formtatConfig = this.formtatConfig(this.config)
      }
      return formtatConfig
    }
  },
  methods: {
    // 对外部数据统一处理 转换为基础弹窗组件统一数据
    formtatConfig (config) {
      let configData = {
        ...config,
        contentText: config.tipContents || '',
        isShowCloseBtn: !!config.closeButtonPos,
        popupType: config.popupSize ? config.popupSize.toLowerCase() : 'middle',
        tipsText: config.notShowText,
        isShowCheckbox: !!config.notShowText
      }
      return configData
    }
  }
}
</script>
<style scoped lang="stylus">
  .modal-content{
    padding: 50px 0 68px 0;
    text-align: center;
  }
  .title{
    font-size: 22px;
    line-height 30px;
    color: #666666;
  }
  .qr-code{
    margin-top: 44px;
  }
</style>