import axios from 'axios'
import formurlencoded from 'form-urlencoded'
import cookies from 'cookies-js'
import vf2e from '../../vf2e.config'

//数组排序，按照对象里的某个属性的值排序
function compare(property) {
  return function (a, b) {
    var value1 = a[property]
    var value2 = b[property]
    return value1 - value2
  }
}

//设置cookie
function setCookie(k, v) {
  let COOKIE_LIFETIME = 365 * 24 * 60 * 60 * 1000
  let HOST_NAME = location.hostname
  let COOKIE_DOMAIN = HOST_NAME.slice(HOST_NAME.indexOf('.'))
  cookies.set(k, v, {domain: COOKIE_DOMAIN, expires: new Date(Date.now() + COOKIE_LIFETIME), path: '/'})
}

//删除cookie
function clearCookie(objName) {
  var str = objName + "=''"
  var date = new Date()
  date.setTime(date.getTime() - 10000)
  str += "; expires=" + date.toGMTString()
  document.cookie = str
}

// 获取cookie
function getCookies() {
  var str = document.cookie
  var cookies = {}
  var orginCookies = str.split('; ')
  for (var index = 0; index < orginCookies.length; index++) {
    var cookieInfo = orginCookies[index].split('=')
    cookies[cookieInfo[0]] = cookieInfo[1]
  }
  return cookies
}

//判断是否是iPad
function isOnIpad() {
  if (navigator.userAgent.toLowerCase().match(/ipad/i)) return true
  else return false
}
//判断是不是mac系统
function detectIsMac(){
  if(!/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) && ((navigator.platform == "Mac68K") || (navigator.platform == "MacPPC") || (navigator.platform == "Macintosh") || (navigator.platform == "MacIntel"))){
    return true
  }else{
    return false
  }
}

//判断浏览器所在的操作系统 用于判断
function detectOS() {
  let sUserAgent = navigator.userAgent
  let isWin = (navigator.platform == "Win32") || (navigator.platform == "Windows")
  let isMac = (navigator.platform == "Mac68K") || (navigator.platform == "MacPPC") || (navigator.platform == "Macintosh") || (navigator.platform == "MacIntel")
  if (isMac) return "Mac"
  let isVersionMacLess10 = detectIsMac()
  if(isVersionMacLess10){
    let versionArr = navigator.userAgent.match(/\d+\_\d+(_\d+)?/)[0].split('_')
    if(versionArr[0] < 10 || (versionArr[0] = 10 && versionArr[1] <= 10 )){
      return "versionMacLess10" //pc Mac系统版本小于10.10
    }
  }
  let isUnix = (navigator.platform == "X11") && !isWin && !isMac
  if (isUnix) return "Unix"
  let isLinux = (String(navigator.platform).indexOf("Linux") > -1)
  if (isLinux) return "Linux"
  if (isWin) {
    let iswinXP = sUserAgent.indexOf("Windows NT 5.1") > -1 || sUserAgent.indexOf("Windows XP") > -1
    if(iswinXP) return "winXP"
    let isWin2003 = sUserAgent.indexOf("Windows NT 6.1") > -1 || sUserAgent.indexOf("Windows 7") > -1
    if (isWin2003) return "Win7"
    let isWin10 = sUserAgent.indexOf("Windows NT 10") > -1 || sUserAgent.indexOf("Windows 10") > -1
    if (isWin10) return "Win10"
  }
  return "other"
}

//用于版本号比较
function compareVersion(curVer,verString){
  let arr1 = curVer.split('.')
  let arr2 = verString.split('.')
  // 将两个版本号拆成数字 
  let minL = Math.min(arr1.length,arr2.length)
  let pos = 0 // 当前比较位
  let diff = 0 // 当前为位比较是否相等
  // 逐个比较如果当前位相等则继续比较下一位
  while(pos < minL){
    diff = parseInt(arr1[pos]) - parseInt(arr2[pos])
    if(diff != 0){
      break
    }
    pos ++
  }
  if (diff >= 0) {
    return true
  }else{
    return false
  }
}

//将时间格式化为：m.d 月.日
function timeFormat(time) {
  var t = new Date(time)
  var months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
  var month = months[t.getMonth()]
  var date = t.getDate()
  if (date < 10) {
    date = "0" + date
  }
  return month + "." + date
}

//将时间格式化为：年-月-日 时:分
function timeFormatAll(time) {
  var t = new Date(time)
  var months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
  var month = months[t.getMonth()]
  var date = t.getDate()
  var hour = t.getHours()
  var min = t.getMinutes()
  if (date < 10) {
    date = "0" + date
  }
  if (hour < 10) {
    hour = "0" + hour
  }
  if (min < 10) {
    min = "0" + min
  }
  return t.getFullYear() + "-" + month + "-" + date + " " + hour + ":" + min
}

//将时间格式化为：周 时:分
function weekTime(timestamp) {
  timestamp = fixTimezoneOffset(timestamp)
  var time = new Date(timestamp)
  var hour = time.getHours()
  var min = time.getMinutes()
  var week = ''
  switch (time.getDay()) {
  case 0:
    week = '周天'
    break
  case 1:
    week = '周一'
    break
  case 2:
    week = '周二'
    break
  case 3:
    week = '周三'
    break
  case 4:
    week = '周四'
    break
  case 5:
    week = '周五'
    break
  case 6:
    week = '周六'
    break
  }
  function addZero(num) {
    if (num < 10) {
      return '0' + num
    } else {
      return num
    }
  }

  function fixTimezoneOffset(timestampCn) {
    var date = new Date()
    //当地时间与GMT时间的时间差值(毫秒)
    var offset = date.getTimezoneOffset() * 60 * 1000
    //得到格林威治时间戳
    var GMTTimestamp = timestampCn + offset
    //统一时间
    timestampCn = GMTTimestamp + (8 * 3600 * 1000)
    return timestampCn
  }

  return week + ' ' + addZero(hour) + ':' + addZero(min)
}

//获取当前周 开始时间-结束时间
function currentWeek() {
  var now = new Date() //当前日期
  var nowDayOfWeek = now.getDay() //今天本周的第几天
  var nowDay = now.getDate() //当前日
  var nowMonth = now.getMonth() //当前月
  var nowYear = now.getYear() //当前年
  //获得本周的开始日期
  var weekStartDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek)
  var weekEndDate = new Date(nowYear, nowMonth, nowDay + (6 - nowDayOfWeek))

  function weekF(date) {
    var mymonth = date.getMonth() + 2
    var myweekday = date.getDate() + 1
    if (mymonth < 10) {
      mymonth = "0" + mymonth
    }
    if (myweekday < 10) {
      myweekday = "0" + myweekday
    }
    return (mymonth + "." + myweekday)
  }

  return weekF(weekStartDate) + "-" + weekF(weekEndDate)
}

//格式化时间为：年-月-日
function dateTime(timestamp) {
//跨时区统一
  timestamp = fixTimezoneOffset(timestamp)
  var time = new Date(timestamp)
  var year = time.getFullYear()
  var months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
  var month = months[time.getMonth()]
  var date = time.getDate()

  function addZero(num) {
    if (num < 10) {
      return '0' + num
    } else {
      return num
    }
  }

  function fixTimezoneOffset(timestampCn) {
    var date = new Date()
    //当地时间与GMT时间的时间差值(毫秒)
    var offset = date.getTimezoneOffset() * 60 * 1000
    //得到格林威治时间戳
    var GMTTimestamp = timestampCn + offset
    //统一时间
    timestampCn = GMTTimestamp + (8 * 3600 * 1000)
    return timestampCn
  }

  return year + '-' + month + '-' + addZero(date)
}

//把时间格式化为：年-月-日 周 时:分 或者 年-月-日 时:分
function formatDate(timestamp, isWeek) {
  //跨时区统一
  timestamp = fixTimezoneOffset(timestamp)
  var time = new Date(timestamp)
  var year = time.getFullYear()
  var months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
  var month = months[time.getMonth()]
  var date = time.getDate()
  var hour = time.getHours()
  var min = time.getMinutes()
  var week = ''
  switch (time.getDay()) {
  case 0:
    week = '周天'
    break
  case 1:
    week = '周一'
    break
  case 2:
    week = '周二'
    break
  case 3:
    week = '周三'
    break
  case 4:
    week = '周四'
    break
  case 5:
    week = '周五'
    break
  case 6:
    week = '周六'
    break
  }
  function addZero(num) {
    if (num < 10) {
      return '0' + num
    } else {
      return num
    }
  }

  function fixTimezoneOffset(timestampCn) {
    var date = new Date()
    //当地时间与GMT时间的时间差值(毫秒)
    var offset = date.getTimezoneOffset() * 60 * 1000
    //得到格林威治时间戳
    var GMTTimestamp = timestampCn + offset
    //统一时间
    timestampCn = GMTTimestamp + (8 * 3600 * 1000)
    return timestampCn
  }

  if (isWeek) {
    return year + '-' + month + '-' + addZero(date) + ' ' + week + ' ' + addZero(hour) + ':' + addZero(min)
  } else {
    return year + '-' + month + '-' + addZero(date) + ' ' + addZero(hour) + ':' + addZero(min)
  }
}

/*
 * 后端打点方法
 * digital library 点击事件(打点记录)
 * @clickEventSources:
 * 1-pc 2-ipad 3-PC_BOOK_ENTRANCE 4-IPAD_BOOK_ENTRANCE  5-PC_FINISHED_BOOK_ENTRANCE  6-IPAD_FINISHED_BOOK_ENTRANCE 7-PC_RC_READER_ENTRANCE
 * @clickEventType: 1-封面 2-借书按钮 3-开始阅读 4-翻页 5-最后一页 6-声音开关 7-语速 8-activity
 * @clickEventSubType(非必填): 4-1自动翻页 4-2手动翻页; 6-1静音 6-2打开声音 7-具体倍数
 * @rate(非必填): 点击增加量
 */
function clickEvent(studentId, bookId, clickEventSources, clickEventType, clickEventSubType, rate) {
  var param = {
    studentId: studentId,
    bookId: bookId,
    clickEventSources: clickEventSources,
    clickEventType: clickEventType
  }
  if (clickEventSubType) {
    param.clickEventSubType = clickEventSubType
  }
  if (rate) {
    param.rate = rate
  }
  axios.post('/rest/learninggw/api/pc/service/digitlib/clickEvent', formurlencoded(param))
    .then((res) => {
    })
    .catch((err) => {
    })
}

var clickEventConst = {
  PC: 1,
  IPAD: 2,
  PC_HOMEPAGE_ENTRANCE: 11,
  PC_FINISHED_BOOK_ENTRANCE: 13,
  PC_MC_READER_ENTRANCE: 15,
  CLICK_BOOKCOVER: 1,
  CLICK_BORROW_BUTTON: 2,
  START_READING: 3,
  TURN_PAGE: 4,
  TURN_PAGE__AUTO: 1,
  TURN_PAGE__MANUAL: 2,
  LAST_PAGE: 5,
  SOUND_SWITCH: 6,
  SOUND_SWITCH__MUTE: 1,
  SOUND_SWITCH__UNMUTE: 2,
  VOICE_SPEED: 7,
  ACTIVITY: 8
}

/* 宝石商城 点击事件(打点记录)
 * 打点接口文档见Wiki
 */
function giftShopClickEvent(clickEventType, clickEventSubType, goodsId) {
  var param = {
    studentId: cookies.get('studentId'),
    goodsId: goodsId, //商品Id
    clickEventSysType: 1, //学习中心积分商城为1
    clickEventSources: cookies.get('isFromApp') ? 2 : 1, //ipad app为2, web为1,
    clickEventType: clickEventType,
    clickEventSubType: clickEventSubType
  }
  axios.post('/rest/learninggw/api/pc/eshop/click/clickEvent', formurlencoded(param))
    .then((res) => {
    })
    .catch((err) => {
    })
}

//判断object 是否为空
function isEmptyObject(e) {
  var t
  for (t in e) {
    return true
  }
  return false
}

//数组查找
function arrayFind(arr, fn) {
  let item
  for (let i = 0, len = arr.length; i < len; i++) {
    if (fn(arr[i])) {
      item = arr[i]
      break
    }
  }
  return item
}

//dom事件取消
const off = (function () {
  if (document.removeEventListener) {
    return function (element, event, handler) {
      if (element && event) {
        element.removeEventListener(event, handler, false)
      }
    }
  } else {
    return function (element, event, handler) {
      if (element && event) {
        element.detachEvent('on' + event, handler)
      }
    }
  }
})()

//事件绑定
const on = (function () {
  if (document.addEventListener) {
    return function (element, event, handler) {
      if (element && event && handler) {
        element.addEventListener(event, handler, false)
      }
    }
  } else {
    return function (element, event, handler) {
      if (element && event) {
        element.detachEvent('on' + event, handler)
      }
    }
  }
})()

//事件绑定一次
function once(el, event, fn) {
  let listener = function () {
    if (fn) {
      fn.apply(this, arguments)
    }
    off(el, event, listener)
  }
  on(el, event, listener)
}

/**
 * @description 往某一个元素节点添加样式类
 * @param {Object} element  元素节点
 * @param {String} className 添加的类名称，如 "name luo ..." or "name"
 */
function addClass(element, className) {
  if ((typeof className === "string" ) && className.length > 0) {
    let classNameArray = className.split(" ")
    for (let i = 0; i < classNameArray.length; i++) {
      element.classList.add(classNameArray[i])
    }
  }
  return this
}

/**
 * @description 往某一个元素节点删除样式类
 * @param {Object} element 元素节点
 * @param {String} className 删除的样式类名称，"name luo ..." or "name"
 */
function removeClass(element, className) {
  if ((typeof className === "string") && className.length > 0) {
    let classNameArray = className.split(" ")
    for (let i = 0; i < classNameArray.length; i++) {
      element.classList.remove(classNameArray[i])
    }
  }
  return this
}

//防止xss攻击，对于用户输入做JavaScript标签的验证
function xssCheck(str, reg) {
  if (!str) {
    return false
  }
  str = decodeURIComponent(str)
  return str ? (str.search(reg || /[<">']|(javascript)+/g) == -1 ? false : true) : true
}

//数组和对象的深拷贝
function deepCopy(ob) {
  if ((typeof ob) != 'object') {
    return ob
  }
  if (ob instanceof Array) {
    var newArray = []
    for (var obj of ob) {
      newArray.push(deepCopy(obj))
    }
    return newArray
  }
  var newObj = {}
  //遍历属性
  for (var key in ob) {
    if ((typeof ob[key]) == 'object') {
      newObj[key] = deepCopy(ob[key])
      if (ob[key] instanceof Array) {
        var newArray = []
        //数组里如果有对象
        for (var o of ob[key]) {
          newArray.push(deepCopy(o))
        }
        newObj[key] = newArray
      }
      if (ob[key] instanceof Date) {
        newObj[key] = ob[key]
      }
    } else {
      newObj[key] = ob[key]
    }
  }
  return newObj
}

// localStorage的使用工具方法
var localStorageUtil = {
  set: function (key, value) {
    if (window.localStorage) {
      localStorage.setItem(`vk_lc_${key}`, value)
    } else {
      //'window.localStorage 不存在'
    }
  },
  get: function (key) {
    var v = localStorage.getItem(`vk_lc_${key}`)
    if (v == "true") {
      return true
    }
    if (v == "" || v == "false" || v == null) {
      return false
    }
    return v
  },
  del: function (key) {
    if (typeof key === 'string') {
      try {
        return localStorage.removeItem(`vk_lc_${key}`)
      } catch (error) {
        return false
      }
    } else {
      return false
    }
  }
}

//localStorage的使用工具方法2
/*
  global为全局模块名，只能存和取，不能删除
*/
var localStorageUtil_Module = {
  set: function (moduleName, key, value) { // moduleName:string, key:string
    value = value?value:''
    if(window.localStorage){ // 存在当前模块
      if(localStorage.getItem(moduleName)){
        let data = JSON.parse(localStorage.getItem(moduleName))
        data[key] = value
        localStorage.setItem(moduleName, JSON.stringify(data))
      } else { // 不存在当前模块
        let obj = {}
        obj[key] =value
        localStorage.setItem(moduleName, JSON.stringify(obj))
      }
    } else {
      console.log('window.localStorage 不存在')
    }
  },
  get: function (moduleName, key) { // moduleName:string, key:string
    let data = JSON.parse(localStorage.getItem(moduleName))
    if (data.hasOwnProperty(key)) {
      return data[key]
    } else {
      return '没有找到该key'
    }
  },
  del: function (moduleName, key) {
    if(moduleName == 'global'){ // moduleName为global的不能删除
      console.log('不能删除全局变量')
      return false
    }
    let result = JSON.parse(localStorage.getItem(moduleName))
    if(result && moduleName && !key){ //localStorage中有此模块，用户只传模块名，不传key
      localStorage.removeItem(moduleName)
    } else if(result && moduleName && key) { //localStorage中有此模块，用户传模块名和key
      if (result.hasOwnProperty(key)) { // 此模块中有要删除的key
        delete result[key]
        localStorage.setItem(moduleName, JSON.stringify(result))
      } else {
        console.log(`没有找到${moduleName}模块中的${key}键`)
      }
    } else if (!result){ //localStorage中找不到此模块
      console.log('没有找到该模块')
    }
  }
}

//获取某个月份的总天数，不传参数的话，默认是当前月取值1-12
function getMonthDays(month) {
  var curDate = new Date()
  /* 获取当前月份 */
  var curMonth = curDate.getMonth()
  if (month) {
    curMonth = month
  }
  // 生成实际的月份
  curDate.setMonth(curMonth)
  /* 0为上个月的最后一天 */
  curDate.setDate(0)
  /* 返回当月的天数 */
  return curDate.getDate()
}

/**
 * @description 计算字符串字符长度方法 1个汉字 = 2个英文字符
 * @param {String}  字符串
 */

function getStrLength(str) {
  let len = str.length
  let pattern = /[\u4e00-\u9fa5]+/g
  let contents = str
  let arr = null
  let strArr = ''
  if (contents.match(pattern)) {
    arr = contents.match(pattern)
    if (arr.length === 1) {
      strArr = arr[0]
    } else {
      strArr = arr.join('')
    }
  }
  return len + strArr.length
}

/**
 * 跳转到首页，区分ipad原生首页和h5首页
 */

function gotoFirstPage() {
  if (cookies.get('isFromApp') === '1') {
    let iFrame = document.createElement("iframe")
    iFrame.setAttribute("src", 'vkappbridge://history/back')
    iFrame.setAttribute("style", "display:none;")
    document.body.appendChild(iFrame)
    //把它从dom上移除掉
    iFrame.parentNode.removeChild(iFrame)
    iFrame = null
  } else {
    // if(from){
    //   Vm.$router.push('/newmajor')
    // }else{
    //   Vm.$router.push('/')
    // }
    Vm.$router.push('/newmajor')
    
  }
}
// 调起ipad bridge
function dispatchForIpad(src) {
  let _iframe = document.createElement("iframe")
  _iframe.setAttribute("src", src)
  _iframe.setAttribute("style", "display:none;")
  document.body.appendChild(_iframe)
  _iframe.parentNode.removeChild(_iframe)
  _iframe = null
}

//获取Ipad App版本号
function getAppVersion() {
  let appUserAgentInformaition = navigator.userAgent
  let appUserAgentInformaitionArray = appUserAgentInformaition.split(" ")
  let version = ""
  appUserAgentInformaitionArray.forEach((item, index) => {
    if (item.indexOf('study-app') > -1) {
      version = item.slice(10)
    }
  })
  return version
}

// 安卓设备适配设置
let handleViewportOnAndroid = {
  setViewport() {
    if (!!navigator.userAgent.toLowerCase().match(/-apad/i)) {
      let scale = 0.8
      if (window.screen && window.screen.availWidth && window.screen.availHeight) {
        if (window.screen.availWidth < 860 || window.screen.availHeight < 560) scale = 0.65
        if (window.screen.availHeight > 559 && window.screen.availHeight < 601) scale = 0.75
      }
      if (!!navigator.userAgent.toLowerCase().match(/vrd-al09/i) || !!navigator.userAgent.toLowerCase().match(/sht-w09/i)) scale = 0.68
      let metaEl = document.createElement('meta') //创建一个style元素
      let htmlHead = document.head || document.getElementsByTagName('head')[0] //获取head元素
      metaEl.setAttribute('name', "viewport")
      metaEl.setAttribute('content', `width=device-width, height=device-height, initial-scale=${scale}, maximum-scale=1.0, user-scalable=no`)
      metaEl.setAttribute('data-name', "Android")
      htmlHead && htmlHead.appendChild(metaEl)
    }
  },
  delViewport() {
    if (!!navigator.userAgent.toLowerCase().match(/-apad/i)) {
      let htmlHead = document.head || document.getElementsByTagName('head')[0] //获取head元素
      let mate = htmlHead.querySelector('meta[data-name=Android]')
      htmlHead && mate && htmlHead.removeChild(mate)
    }
  }
}
//判断是否是lc域名下的子模块
function isLcSubmodules(url){
  // eslint-disable-next-line
  let urlMatch = url.match(/^http[s]?:\/\/([\w\-\.\/]+)/)
  let urlStr = url
  let nameValue = ''
  let moduleList = vf2e.moduleList
  let isLcSubmodulesFlag = false
  if (urlMatch && urlStr.indexOf('lc') > -1) {
    urlStr = urlMatch[1]
  } else if(!urlMatch) {
    urlStr = url
  }
  nameValue = urlStr.split('/')[1]
  for(let item in moduleList){
    if(moduleList[item].name === nameValue) isLcSubmodulesFlag = true
  }
  return isLcSubmodulesFlag
}
function fixMeta(ratio) {
  let zoomRatio = 0.8 // 默认0.8
  if(ratio) zoomRatio = ratio
  let meta = document.querySelector('meta[name="viewport"]')
  meta.setAttribute('content', `width=device-width, height=device-height, initial-scale=${zoomRatio}, maximum-scale=${zoomRatio},minimum-scale=${zoomRatio}, user-scalable=no`)
}

function deviceAdapter() {
  const isOnAPad = navigator.userAgent.toLowerCase().match(/apad/i) ? true:false // 安卓pad
  if(isOnAPad) fixMeta()
}

export {
  localStorageUtil, deepCopy, getMonthDays, getStrLength
}

export default{
  getStrLength,
  getMonthDays,
  deepCopy,
  localStorageUtil,
  gotoFirstPage,
  setCookie,
  clearCookie,
  getCookies,
  formatDate,
  isOnIpad,
  detectOS,
  compareVersion,
  clickEvent,
  clickEventConst,
  weekTime,
  dateTime,
  isEmptyObject,
  arrayFind,
  currentWeek,
  timeFormat,
  giftShopClickEvent,
  compare,
  once,
  addClass,
  removeClass,
  timeFormatAll,
  xssCheck,
  on,
  off,
  dispatchForIpad,
  getAppVersion,
  localStorageUtil_Module,
  handleViewportOnAndroid,
  isLcSubmodules,
  deviceAdapter
}
