<template>
  <div v-show="showing" class="vk-dialog-adultapp">
    <div class="bg"></div>
    <div class="container">
      <img src="./img/close.png" class="close" @click="hide">
      <div class="container-bg"></div>
      <div class="container-inner">
        <img src="./img/label.png" class="label">
        <div class="info-panel">
          <div class="part part1">
            <div class="title">方式一</div>
            <div class="bottom">
              <div class="info-part part1">
                <img src="./img/logo.png">
                <div class="text">
                  打开<span class="highlight1">大咖英语APP</span>
                </div>
              </div>
              <div class="info-part arrow">
                <img src="./img/arrow.png">
              </div>
              <div class="info-part part2">
                <img src="./img/desc2.png">
                <div class="text">
                  选择<span class="highlight1">北美外教-我的课表</span>
                </div>
              </div>
              <div class="info-part arrow">
                <img src="./img/arrow.png">
              </div>
              <div class="info-part part3">
                <img src="./img/desc3.png">
                <div class="text">
                  在<span class="highlight1">已上课程</span>开始AI随堂练
                </div>
              </div>
            </div>
          </div>
          <div class="part part2">
            <div class="title">方式二</div>
            <div class="bottom">
              <div class="left">
                <div class="info">
                  <div>
                    打开<span class="highlight1">微信-扫一扫，</span>扫描右方二维码，
                  </div>
                  <div>
                    在<span class="highlight1">大咖英语APP</span>里进行AI随堂练习~
                  </div>
                </div>
              </div>
              <div class="right">
                <img src="./img/qrcode.png" class="qrcode">
              </div>
            </div>
          </div>
          <div class="tips">注：如还没有大咖英语APP，请先到手机应用市场下载安装</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      showing: false
    }
  },
  mounted () {
    console.log('ok')
  },
  methods: {
    show () {
      this.showing = true
    },
    hide () {
      this.showing = false
    }
  }
}
</script>
<style lang="stylus" scoped>
.vk-dialog-adultapp
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  .bg
    width 100%
    height 100%
    background: rgba(0,0,0,0.6);
    position absolute
    z-index 1
  .container
    width: 900px;
    height: 600px;
    background: linear-gradient(180deg, #C96EF2 0%, #A579FF 100%);
    border-radius: 34px;
    box-sizing: border-box;
    padding: 10px 14px;
    position absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    z-index: 2
    .container-bg
      width: 880px;
      height: 570px;
      border-radius: 20px;
      background: #E3DFEB;
      position absolute
      bottom: 10px;
      left: 10px;
      z-index -1
    .container-inner
      width: 872px;
      height: 580px;
      border-radius: 20px;
      background: #FFF;
      img.label
        width 70px
        height 198px
        position absolute
        top: 4px;
        left: 30px;
      .info-panel
        padding: 47px 70px 37px 102px
        .tips
          line-height: 150%;
          margin-top: 10px
          color: #999;
          font-size: 13px;
        .part
          background: #FAFAFA;
          padding 24px
          border-radius: 12px;
          position relative
          &:not(:first-child)
            margin-top 16px
          .left
            width 360px
          .right
            width 204px
            text-align center
          .title
            width: 68px;
            height: 24px;
            line-height: 24px;
            background: #88CCDF;
            border-radius: 12px;
            text-align center
            font-size: 14px
            font-weight: 500
            color #fff
            position absolute
          .bottom
            font-size: 16px
            line-height: 150%
            color: #333
            display flex
            justify-content: space-between
            align-items center
          .highlight1
            color #759DFF
          img.desc1
            width: 204px;
          img.qrcode
            width: 124px;
          &.part1
            .bottom
              margin-top 32px
            .info-part
              text-align center
              .text
                margin-top 5px
              &.part1
                width: 136px
                align-self end
                img
                  width 90px
                  margin-bottom 25px
              &.part2
                width: 177px
                align-self end
                img
                  width 160px
                .text
                  position: relative;
                  left: 7px;
              &.part3
                width: 223px
                align-self end
                img
                  width 177px
                .text
                  position: relative;
                  right: 14px;
              &.arrow
                img
                  width 50px
    .close
      width 32px
      height 32px
      position absolute
      top: 33px;
      right: 30px;
      cursor: pointer
      pointer-events: all
</style>
