<template>
<div class="dialogWarpper">
  <div class="vk-dialog-container">
    <div class="dialog-bg" :class="[dialogBgSty]" :style="{backgroundImage: backGroundImg ? `url(${backGroundImg}` : ''}"  @click.self="imgClick">
      <div class="topWarp" :class="{bgPosition: headImage}">
        <div class="moodImg" v-if='mood' :class="moodImgSty"></div>
        <div class="moodImg bgImg" v-if="headImage" :style="{backgroundImage: `url(${headImage})`}"></div>
        <div class="dialog-title" v-if='title && !headImage' v-text="title" :class="{noMood: !mood}"></div>
      </div>
      <div class="slotWarpper" v-if="isUseSlot" :class="contentSty">
        <slot></slot>
      </div>
      <div class="contentWarpper" v-if="!isUseSlot" :class="contentSty">
        <div class="textBox" :class="dialogBgSty" v-if="!Array.isArray(contentText)" v-html='contentText'></div>
        <div class="conent-box"  v-for="(item, index) in contentText" :key="index">
          <template v-if="Array.isArray(contentText)">
            <img class="content-img" :src="item.content" v-if="item.contentType === 'IMAGE'" />
            <div class="content-text" v-if="item.contentType === 'TEXT'"> {{item.content}}</div>
          </template>
        </div>
      </div>
      <footer v-if="!isUseSlot">
        <div class="button-box" v-if="!Array.isArray(buttonList)">
          <div class="dialog-btn btn-left" v-if="leftButtonText" @click="leftBtnEvent"> {{leftButtonText}}</div>
          <div class="dialog-btn btn-right" @click="rightBtnEvent"> {{rightButtonText}}</div>
        </div>
        <div class="button-box button-flex" v-else>
          <template v-for="(item, index) in buttonList">
            <common-btn :btnColorType="item.buttonStyle" :text="item.text" btnSize="large" :minWidth="btnMinWidth[popupType]" @btnClick="btnEvent[index]" :key="index"></common-btn>
          </template>
        </div>
        <div class="noTip" v-if="tipsText && isShowCheckbox" @click='checkIconClick'>
          <div class="defaultBtn">
            <div class="checkIcon" v-if='checkIconKey'></div>
          </div>
          <div class="customTip" v-html='tipsText'></div>
        </div>
        <div class="noTip" v-if="tipsText && !isShowCheckbox" v-html='tipsText'></div>
      </footer>
      <div class="closebtn" v-if = 'isShowCloseBtn' @click="closebtnEvent" :class="closeButtonPos" :style="{backgroundImage: closeButtonImage ? `url(${closeButtonImage}` : ''}"></div>
    </div>
  </div>
</div>
</template>
<script>
import commonBtn from '../common_button/common_button'
export default {
  data: function () {
    return {
      checkIconKey: false,
      dialogBgSty: {
        'smallSty': this.popupType === 'small',
        'middleSty': this.popupType === 'middle',
        'largeSty': this.popupType === 'large'
      },
      moodImgSty: {
        'loseMood': this.mood === 'lose',
        'happyMood': this.mood === 'happy',
        'warnMood': this.mood === 'warning',
        'energyMood': this.mood === 'energy',
        'starMood': this.mood === 'star',
        'fortuneMood': this.mood === 'fortune',
        'openFortuneMood': this.mood === 'openFortune'
      },
      moodImgDefault: {
        'happy': require('../../assets/img/common/popups_pic_positive.png')
      },
      contentSty: {
        'noTitle_hasMood': !this.title && this.mood,
        'noMood_hasTitle': !this.mood && this.title,
        'noTitle_noMood': !this.title && !this.mood,
        'hasTips': this.tipsText
      },
      // 4.0新增
      btnMinWidth: {
        'small': 160,
        'middle': 200,
        'large': 200
      },
      // 4.0新增
      btnEvent: {
        0: this.leftBtnEvent,
        1: this.rightBtnEvent
      }
    }
  },
  props: {
    // 4.0新增
    headImage: {
      type: String
    },
    // 4.0新增
    buttonList: {
      type: Array
    },
    // 4.0新增
    backGroundImg: {
      type: String
    },
    // 4.0新增
    closeButtonPos: {
      type: String
    },
    // 4.0新增
    closeButtonImage: {
      type: String
    },
    isUseSlot: { // 是否使用插槽，默认为false
      type: Boolean,
      default: false
    },
    popupType: { // 弹窗类型，默认为small
      type: String,
      default: 'small',
      validator: function (value) { // 这个值必须匹配下列字符串中的一个
        return ['small', 'middle', 'large'].indexOf(value) !== -1
      }
    },
    mood: { // 情绪,与isUseSlot不互斥
      type: String,
      validator: function (value) { // 这个值必须匹配下列字符串中的一个
        return ['lose', 'happy', 'warning', 'energy', 'star', 'openFortune', 'fortune'].indexOf(value) !== -1
      }
    },
    title: { // 标题,与isUseSlot不互斥
      type: String
    },
    contentText: { // 内容文案,与isUseSlot互斥
      type: [String, Array],
      default: '----'
    },
    leftButtonText: { // 左侧按钮文案,与isUseSlot互斥
      type: String
    },
    rightButtonText: { // 右侧按钮文案,与isUseSlot互斥
      type: String,
      default: '确定'
    },
    tipsText: { // 脚部提示文案
      type: String
    },
    isShowCheckbox: { // 是否显示复选框, 默认不显示
      type: Boolean,
      default: false
    },
    isShowCloseBtn: { // 是否显示右上角按钮,默认不显示
      type: Boolean,
      default: false
    },
    leftBtnEvent: { // 左侧btn事件
      type: Function,
      default: () => {
        console.log('左侧按钮点击执行方法')
      }
    },
    rightBtnEvent: { // 右侧btn事件
      type: Function,
      default: () => {
        console.log('右侧按钮点击执行方法')
      }
    },
    closebtnEvent: { // 关闭按钮事件
      type: Function,
      default: () => {
        console.log('右上角关闭按钮点击执行方法')
      }
    },
    checkboxEvent: { // 提示复选框事件
      type: Function,
      default: (val) => {
        console.log('复选框按钮选中状态：' + val)
      }
    }
  },
  components: {
    commonBtn
  },
  methods: {
    checkIconClick () {
      this.checkIconKey = !this.checkIconKey
      this.checkboxEvent(this.checkIconKey)
    }
  }
}
</script>

<style lang="stylus" scoped>
  @import '../../assets/css/basePopUp.styl'
  .dialogWarpper
    width:100%
    height:100%
    position:fixed
    background-color: rgba(0, 0, 0, 0.6)
    top:0px
    left:0px
    z-index: 22
    display:flex
    justify-content:center;
    align-items:center;
    .vk-dialog-container
      box-sizing : border-box
      // padding:6px
      border-radius:36px;
      background: linear-gradient(135deg, rgba(221,204,255,0.7), rgba(255,255,255,0.14), rgba(255,255,255,0.1), rgba(221,204,255,0.7))
      .dialog-bg
        position:relative
        width:100%
        height:100%
        border-radius:32px;
        background:rgba(255,255,255,1);
        background-repeat no-repeat
        background-size 100% 100%
        &.smallSty
          width: 450px
          height: 360px
        &.middleSty
          width: 600px
          height: 450px
        &.largeSty
          width: 900px
          height: 580px
        .topWarp
          display: inline-block
          width:100%
          // height: $topWarpHeight
          // 4.0修改
          height 218px
          position:absolute
          // top: $topWarpTop
          // 4.0修改
          top: -140px
          left:0
          .bgImg // 4.0修改
            border-radius: 32px 32px 0px 0px
            background-position: center
            background-size: contain
          .moodImg
            width:100%
            height: $topWarp_moodHeight
            &.loseMood
              background: url('../../assets/img/common/popups_pic_negative.png') center no-repeat
            &.happyMood
              background: url('../../assets/img/common/popups_pic_positive.png') center no-repeat
            &.warnMood
              background: url('../../assets/img/common/popups_pic_warning.png') center no-repeat
            &.energyMood
              background: url('../../assets/img/common/popups_pic_award.png') center no-repeat
            &.starMood
              background: url('../../assets/img/common/popups_pic_star.png') center no-repeat
            &.fortuneMood
              background: url('../../assets/img/common/fortune.png') center no-repeat
            &.openFortuneMood
              background: url('../../assets/img/common/fortune_active.png') center no-repeat
          .dialog-title
            margin-top: $titleMarginTop_hasMood
            padding: 0 32px;
            box-sizing: border-box;
            text-align: center
            font-size:28px;
            font-family:PingFangSC, PingFangSC-Semibold;
            font-weight:600;
            color:rgba(122,69,230,1);
            line-height:40px;
            overflow:hidden
            white-space: nowrap
            text-overflow: ellipsis
            &.noMood
              // margin-top: $titleMarginTop_noMood
              // 4.0修改
              margin-top: 164px;
        .slotWarpper
          position: absolute;
          top: $contentTop_hasMood_hasTitle
          left: 0;
          right: 0;
          bottom: 0;
          padding: 0 32px
          box-sizing : border-box
          overflow-y: auto
          &.noTitle_hasMood
            top: $contentTop_noTitle_hasMood
          &.hasTips
            bottom: $contentBottom_hasTips
          &.noMood_hasTitle
            top: $contentTop_noMood_hasTitle
          &.noTitle_noMood
            top: 0
        .contentWarpper
          position:absolute
          top: $contentTop_hasMood_hasTitle
          bottom: $contentBottom_noTips
          left:0
          right:0
          padding  0 32px
          display: flex;
          align-items: center
          justify-content: center
          display flex
          flex-direction column
          overflow-y: auto
          &.noTitle_hasMood
            top: $contentTop_noTitle_hasMood
          &.hasTips
            bottom: $contentBottom_hasTips
          &.noMood_hasTitle
            top: $contentTop_noMood_hasTitle
          .content-text
            word-break break-all
            font-size 22px
            font-family PingFangSC, PingFangSC-Regular
            font-weight 400
            text-align left
            color #666666
          .textBox
            width: 100%
            font-size:22px;
            font-family:PingFangSC-Regular,PingFangSC;
            font-weight:400;
            color: rgba(102,102,102,1);
            line-height:30px;
            text-align:center;
            margin:auto
            &.middleSty
              text-align:left
            &.largeSty
              text-align:left
          .conent-box
            width 100%
            height 100%
            img
              width 100%
              height 100%
        footer
          width:100%
          height: $footerHeight
          text-align: center
          position: absolute
          bottom: $footerBottom
          display:flex;
          justify-content:center;
          flex-direction: column;
          .button-flex // 4.0新增
            display flex
            justify-content space-around
          .button-box
            width:100%
            text-align: center
            .dialog-btn
              border-radius:25px;
              width: 160px
              height: 48px
              line-height: 48px
              text-align: center
              display: inline-block
              vertical-align: middle
              box-sizing: border-box
              font-size:24px;
              font-family:PingFangSC;
              font-weight:600;
              cursor: pointer
              &.bigbtnSize // 4.0新增
                width 200px
              &.btn-left
                margin-right: 50px
                background:rgba(255,237,230,1);
                color:rgba(255,102,0,1);
                &:hover
                  opacity: 0.7
              &.btn-right
                background:linear-gradient(225deg,rgba(255,149,63,1) 0%,rgba(255,92,119,1) 100%);
                color:rgba(255,255,255,1);
                &:hover
                  opacity: 0.7
          .noTip
            height:20px
            line-height:20px
            margin-top: 12px
            color:rgba(153,153,153,1)
            font-size: 14px;
            .defaultBtn
              position:relative
              display:inline-block;
              margin-right:5px
              // width:18px;
              // height:18px;
              border-radius:50%
              border:4px solid rgba(216,216,216,1);
              box-sizing : border-box
              vertical-align: sub
              // 4.0 修改
              width 20px
              height 20px
              .checkIcon
                // width:24px;
                // height:24px;
                // background:url('../../assets/img/common/icon_yes.png')
                position:absolute
                // left: -2px;
                // bottom: -4px;
                // 4.0 修改
                width 20px
                height 20px
                background: url('../../assets/img/common/yes_icon.png')
                background-size cover
                left: -4px;
                bottom: -4px;
            .customTip
              vertical-align: top;
              display: inline-block;
        .closebtn
          width:32px
          height:32px
          background:url('../../assets/img/common/closebtn.png') center no-repeat
          position: absolute
          background-size cover
          top:10px
          right:18px
          padding:10px
          box-sizing border-box
          cursor: pointer
          z-index 4
          &.TOPIMAGE
            padding 0
            top -136px
            right 20px
          &.CONTENT
            padding 0
            right 20px
            top 20px
</style>
