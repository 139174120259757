<template>
  <div class="number-wap">
    <div class="text text-cheng"></div>
    <div class="text text-num" v-for="(i,index) in numbers" :style="{backgroundPosition:'0 '+(-i*56-44)+'px',transitionDelay:index*0.2+'s'}" :key="index"></div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      numbers: []
    }
  },
  props: {
    number: {
      type: String,
      required: true,
      default: '0'
    },
    type: {
      type: String,
      required: false,
      default: 'yellow'
    }
  },
  components: {},
  computed: {},
  watch: {
    number: function () {
      this.numberSplit()
    }
  },
  mounted () {
    this.numberSplit()
  },
  methods: {
    numberSplit () {
      let numbers = this.number.split('')
      // let index = numbers.indexOf('.')
      // if (index > -1) {
      //   numbers[index] = 11
      // }
      let arr = []
      for (var i = 0; i < numbers.length; i++) {
        arr.push(0)
      }
      this.numbers = arr
      this.$nextTick(() => {
        this.numbers = numbers
      }, 200)
    }
  }
}
</script>

<style lang="stylus" scoped>
  .number-wap
    overflow: hidden
    display: inline-block
    .text
      width: 32px
      height: 48px
      float: left
      background-position:  0 12px
      transition: background-position 1s
      transition-timing-function: cubic-bezier(0,2,0,0);
      transition-delay: 0.2s
    .text-cheng,.text-num
      background: url("../../assets/img/bong_animation/bong/bg_number.png") no-repeat
      background-size 32px auto
    .text-yellow, .text-x-yellow
      background: url("../../assets/img/bong_animation/bong/bg_number.png") no-repeat
    .text-orange, .text-x-orange
      background: url("../../assets/img/bong_animation/bong/number-orange@2x.png") no-repeat
    .text-x-orange, .text-x-yellow
      background-position: -5px -440px
      transition-delay: 0.2s
      transform-origin: 100% 80%
      // transform: scale(0.7, 0.7)
    .text-cheng
      background-position 0 12px

</style>
