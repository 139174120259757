class HybridPadInstance {
  dispatchForPad (src) {
    let _iframe = document.createElement('iframe')
    _iframe.setAttribute('src', src)
    _iframe.setAttribute('style', 'display:none;')
    document.body.appendChild(_iframe)
    _iframe.parentNode.removeChild(_iframe)
    // _iframe = null
  }
  isOnIpad () { // 判断是否是iPad webview
    return navigator.userAgent.toLowerCase().match(/ipad/i)
  }
  isOnAndroidPad () { // 判断是否是android Pad webview
    return !!navigator.userAgent.toLowerCase().match(/apad/i)
  }
  isOnPcapp () { // 判断是否是pcapp设备
    return !!navigator.userAgent.toLowerCase().match(/vipkid/i)
  }
  deviceType () {
    let client = 'WEB' // 默认是pc设备
    if (this.isOnPcapp()) client = 'PCAPP'// PCAPP
    if (this.isOnIpad()) client = 'IPAD' // IPAD
    if (this.isOnAndroidPad()) client = 'ANDROID'// ANDROID
    return client
  }
  // 判断浏览器所在的操作系统 用于判断
  detectOS () {
    let sUserAgent = navigator.userAgent
    let isWin = (navigator.platform === 'Win32') || (navigator.platform === 'Windows')
    let isMac = (navigator.platform === 'Mac68K') || (navigator.platform === 'MacPPC') || (navigator.platform === 'Macintosh') || (navigator.platform === 'MacIntel')
    if (isMac) return 'Mac'
    let isUnix = (navigator.platform === 'X11') && !isWin && !isMac
    if (isUnix) return 'Unix'
    let isLinux = (String(navigator.platform).indexOf('Linux') > -1)
    if (isLinux) return 'Linux'
    if (isWin) {
      let isWin2003 = sUserAgent.indexOf('Windows NT 6.1') > -1 || sUserAgent.indexOf('Windows 7') > -1
      if (isWin2003) return 'Win7'
      let isWin10 = sUserAgent.indexOf('Windows NT 10') > -1 || sUserAgent.indexOf('Windows 10') > -1
      if (isWin10) return 'Win10'
    }
    return 'other'
  }
  // 获取版本信息
  getAppVersion () {
    const _device = this.deviceType()
    const _ua = navigator.userAgent.toLowerCase()
    let versionRegex = ''
    if (_device === 'IPAD') versionRegex = /study-app\/\d+(\.\d+)*/g
    if (_device === 'ANDROID') versionRegex = /study-apad\/\d+(\.\d+)*/g
    if (_device === 'PCAPP') versionRegex = /vipkid\/\d+(\.\d+)*/g
    if (versionRegex) {
      let appArr = _ua.match(versionRegex)
      if (!!appArr && appArr.length > 0) {
        const _f = _ua.match(versionRegex)[0].split('/')
        if (!!_f && _f.length > 1) return _f[1]
      }
    } else {
      return ''
    }
  }
  // 用于版本号比较
  compareVersion (curVer, verString) {
    let arr1 = curVer.split('.')
    let arr2 = verString.split('.')
    // 将两个版本号拆成数字
    let minL = Math.min(arr1.length, arr2.length)
    let pos = 0 // 当前比较位
    let diff = 0 // 当前为位比较是否相等
    // 逐个比较如果当前位相等则继续比较下一位
    while (pos < minL) {
      diff = parseInt(arr1[pos]) - parseInt(arr2[pos])
      if (diff !== 0) {
        break
      }
      pos++
    }
    return diff > 0
  }
  constructor () {
    return {
      dispatchForPad: (src) => this.dispatchForPad(src),
      isOnIpad: () => this.isOnIpad(),
      isOnAndroidPad: () => this.isOnAndroidPad(),
      isOnPcapp: () => this.isOnPcapp(),
      detectOS: () => this.detectOS(),
      compareVersion: () => this.compareVersion(),
      deviceType: () => this.deviceType(),
      getAppVersion: () => this.getAppVersion()
    }
  }
}

let HybridPad = new HybridPadInstance()
export default HybridPad
