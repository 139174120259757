var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "content pointer-event-none",
      class: [_vm.devEnv ? "dev" : ""],
      attrs: { id: "px-app" }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showCard,
              expression: "showCard"
            }
          ],
          staticClass: "left-info pointer-event-auto",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.openUseInfo($event)
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "user-avatar",
              style: {
                border:
                  _vm.skinData && _vm.skinData.themeColor
                    ? "6px solid" + _vm.skinData.themeColor
                    : ""
              }
            },
            [
              _vm.studentInfo.avatarUrl
                ? _c("img", {
                    attrs: { src: _vm.studentInfo.avatarUrl, alt: "" }
                  })
                : _c("img", {
                    staticClass: "default-img",
                    attrs: {
                      src: require("../../assets/img/homepage/empty.png"),
                      alt: ""
                    }
                  })
            ]
          ),
          _vm._v(" "),
          _vm.isRemind ? _c("span", { staticClass: "red-tip" }) : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "myself-panel",
              style: {
                background:
                  _vm.skinData && _vm.skinData.themeColor
                    ? _vm.skinData.themeColor
                    : ""
              }
            },
            [
              _c("span", { staticClass: "text" }, [
                _vm._v(_vm._s(_vm.studentInfo.englishName))
              ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showChildrens && _vm.showCard,
              expression: "!showChildrens && showCard"
            }
          ],
          staticClass: "container pointer-event-none"
        },
        [
          _c("networkError", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isError,
                expression: "isError"
              }
            ],
            staticClass: "pointer-event-auto",
            attrs: {
              refreshEvent: function() {
                _vm.getCourseCards(false)
              }
            }
          }),
          _vm._v(" "),
          !_vm.isError && _vm.topTip && _vm.topTip.tipContents
            ? _c(
                "div",
                {
                  staticClass: "course-tip pointer-event-auto",
                  on: {
                    click: function($event) {
                      return _vm.location(
                        _vm.topTip.onlineClassId,
                        _vm.topTip.tipType
                      )
                    }
                  }
                },
                _vm._l(_vm.topTip.tipContents, function(tipItem, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "tip-cont",
                      class: [
                        tipItem.contentType == "HIGH_LIGHT_TEXT"
                          ? "highlight"
                          : ""
                      ]
                    },
                    [
                      tipItem.contentType == "IMAGE"
                        ? _c("img", {
                            staticClass: "img",
                            attrs: { src: tipItem.content, alt: "" }
                          })
                        : _c("label", [_vm._v(_vm._s(tipItem.content))])
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isError && _vm.currentShowIndex == 0,
                  expression: "!isError && currentShowIndex==0"
                }
              ],
              staticClass: "nomal-tip left pointer-event-auto",
              on: {
                click: function($event) {
                  return _vm.goto(1)
                }
              }
            },
            [
              _vm._v("历史课程前往"),
              _c("span", [_vm._v("全部课程")]),
              _vm._v("查看")
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    !_vm.isError &&
                    _vm.hasAfterNextWeekClass &&
                    _vm.swiper &&
                    _vm.swiper.activeIndex + 1 === _vm.cardsList.length,
                  expression:
                    "!isError && hasAfterNextWeekClass && swiper && swiper.activeIndex + 1 === cardsList.length"
                }
              ],
              staticClass: "nomal-tip right pointer-event-auto",
              on: {
                click: function($event) {
                  return _vm.goto(2)
                }
              }
            },
            [
              _vm._v("未来课程前往"),
              _c("span", [_vm._v("全部课程")]),
              _vm._v("查看")
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isError,
                  expression: "!isError"
                }
              ],
              staticClass: "cont pointer-event-none"
            },
            [
              _c(
                "swiper",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.cardsList,
                      expression: "cardsList"
                    }
                  ],
                  ref: "mySwiper",
                  staticClass: "swiper-card pointer-event-auto",
                  style: {
                    transform: "scale(" + _vm.ratio + ", " + _vm.ratio + ")"
                  },
                  attrs: { options: _vm.swiperOption },
                  on: {
                    transitionEnd: _vm.transitionEnd,
                    slideChange: _vm.slideChange,
                    resize: function($event) {
                      return _vm.resize()
                    }
                  }
                },
                _vm._l(_vm.cardsList, function(item, index) {
                  return _c(
                    "swiper-slide",
                    {
                      key: index,
                      staticClass: "swiper-slide",
                      class: {
                        scale:
                          _vm.titleClickIn && index === _vm.currentShowIndex
                      }
                    },
                    [
                      _c("Card", {
                        attrs: {
                          skinData: _vm.skinData,
                          cardData: item,
                          taskIconMap: _vm.taskIconMap
                        },
                        on: {
                          disable: _vm.disable,
                          enable: _vm.enable,
                          clickTask: _vm.dealTaskClick
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c("div", {
                staticClass: "swiper-button-prev pointer-event-auto"
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "swiper-button-next pointer-event-auto"
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showChildrens && _vm.showCard
        ? _c("div", { staticClass: "choice-children pointer-event-auto" }, [
            _c("div", { staticClass: "switch-kid" }, [
              _c("div", { staticClass: "mirror" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "close-button", on: { click: _vm.cancel } },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "confirm-button", on: { click: _vm.confirm } },
                [_vm._v("确定")]
              ),
              _vm._v(" "),
              _c("div", {
                staticClass: "right-scroll",
                on: { click: _vm.rightBtnClick }
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "left-scroll",
                on: { click: _vm.leftBtnClick }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "kid-list-container" }, [
                _c(
                  "div",
                  {
                    ref: "list",
                    staticClass: "items-box",
                    on: {
                      mousewheel: function($event) {
                        return _vm.mouseScroll($event)
                      }
                    }
                  },
                  _vm._l(_vm.studentList, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "item",
                        class: _vm.itemClassObject(index),
                        on: {
                          click: function($event) {
                            return _vm.focusOnThisStudent(index)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "test" }, [
                          item.studentTypeSelectImg
                            ? _c("div", { staticClass: "user-type" }, [
                                _c("img", {
                                  attrs: { src: item.studentTypeSelectImg }
                                })
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "user-avatar" }, [
                            item.avatarUrl
                              ? _c("img", { attrs: { src: item.avatarUrl } })
                              : !item.avatarUrl &&
                                (item.avatar == "default" || !item.avatar)
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.userAvatarCollectionProp["default"]
                                  }
                                })
                              : !item.avatarUrl
                              ? _c("img", {
                                  attrs: {
                                    src:
                                      _vm.userAvatarCollectionProp[item.avatar]
                                  }
                                })
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "english-name",
                            attrs: {
                              text: item.englishName,
                              isAutoSlide: false
                            }
                          }),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "chinese-name",
                            attrs: { text: item.name, isAutoSlide: false }
                          })
                        ])
                      ]
                    )
                  }),
                  0
                )
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("VkDialogAdultapp", { ref: "adultappDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }