<template>
  <div class="common-class" :class="[btnColorClass[btnColorType], getBtnSize(btnSize), clickAnimation ? 'clickAnimation' : '']" :style="{minWidth: `${minWidth}px`, width: `${width}px`}" @click="buttonClick">
    <template v-for="(params, index) in text">
      <span v-if="params.contentType === 'TEXT'" :key="index">{{params.content}}</span>
      <img class="icon-img" v-if="params.contentType === 'IMAGE'" :src="params.content" :key="index"/>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    btnColorType: {
      type: String,
      default: 'mainSelect'
    },
    btnSize: {
      type: String,
      default: 'small',
      validator: function (value) { // 这个值必须匹配下列字符串中的一个
        return ['small', 'middle', 'large', 'xlarge'].indexOf(value) !== -1
      }
    },
    minWidth: {
      type: [Number, String],
      default: 160
    },
    width: {
      type: [Number, String],
      default: '100%'
    },
    text: {
      type: Array
    }
  },
  data () {
    return {
      clickAnimation: false,
      btnColorClass: {
        // 强调按钮
        'prominent': 'prominent-color',
        // 主要按钮
        'mainSelect': 'main-color',
        // 次要按钮
        'subordinateSelect': 'subordinate-color',
        // 弱选按钮
        'weakSelect': 'weak-color',
        // 幽灵按钮
        'ghost': 'ghost-color',
        // 文本按钮
        'text': 'text-color',
        // 紫色其他按钮
        'purple': 'purple-color'
      }
    }
  },
  methods: {
    getBtnSize (sizeMark) {
      // debugger
      if (this.text && this.text.length === 1 && this.text[0].contentType === 'IMAGE') {
        return `${sizeMark}IconBtn`
      } else {
        return `${sizeMark}TextBtn`
      }
    },
    buttonClick () {
      this.clickAnimation = true
      this.$emit('btnClick')
      setTimeout(() => {
        this.clickAnimation = false
      }, 1000)
    }
  }
}

</script>
<style lang="stylus">
.common-class
  font-size: 24px
  font-family: PingFangSC, PingFangSC-Semibold
  font-weight: 600
  text-align: center
  color: #ffffff
  display flex
  justify-content center
  align-items center
  // border-radius: 25px
  &:hover
    cursor pointer
    transform scale(1.05)
  &.clickAnimation
    animation:scaleAnimation .3s cubic-bezier(.39,1.51,.79,1.23)
  span
    margin 0 4px
  &.prominent-color
    background: linear-gradient(to bottom right,#ff5b19, #ff8119)
    border-radius: 25px
    box-shadow: 0px 4px 8px 0px
    color #ffffff
  &.main-color
    background: linear-gradient(to bottom right,#ff8519,#ff661a)
    border-radius: 25px
    color #ffffff
  &.subordinate-color
    background: linear-gradient(to bottom right,#ff9933,#ffaa00)
    border-radius: 25px
    color #ffffff
  &.weak-color
    background: #ffeee6
    border-radius: 25px
    color #ff884d
  &.ghost-color
    opacity: 0.2
    background: #000000
    border-radius: 25px
    color #ffffff
  &.text-color
    color #4d87ff
  &.purple-color
    background: linear-gradient(to bottom right, #8A5CE6, #6A4DFF)
    color #FFFFFF
    border-radius: 25px
  &.xlargeTextBtn
    height 60px
    // display flex
    // align-items center
    // padding 0 41px 0 42px
    padding 0 20px
    img
      width 40px
      height 40px
  &.xlargeIconBtn
    height 60px
    width 60px
    border-radius 50%
    // display flex
    // justify-content center
    // align-items center
    img
      width 40px
      height 40px
  &.largeTextBtn
    height 48px
    // display flex
    // align-items center
    // padding 0 41px 0 42px
    padding 0 20px
    img
      width 32px
      height 32px
  &.largeIconBtn
    height 48px
    width 48px
    border-radius 50%
    // display flex
    // justify-content center
    // align-items center
    img
      width 32px
      height 32px
  &.middleTextBtn
    height 40px
    line-height 40px
    padding 0 16px
    img
      width 28px
      height 28px
  &.middleIconBtn
    height 40px
    width 40px
    border-radius 50%
    // display flex
    // justify-content center
    // align-items center
    img
      width 28px
      height 28px
  &.smallTextBtn
    height 32px
    line-height 32px
    padding 0 12px
    img
      width 20px
      height 20px
  &.smallIconBtn
    height 32px
    width 32px
    border-radius 50%
    // display flex
    // justify-content center
    // align-items center
    img
      width 20px
      height 20px
@keyframes scaleAnimation
  0%
    transform: scale(1)
  50%
    transform: scale(0.95)
  100%
    transform: scale(1)
</style>
