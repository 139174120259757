var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vk-button" }, [
    _c(
      "div",
      {
        staticClass: "btn",
        class: [_vm.type.toLowerCase()],
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.goToAction($event)
          }
        }
      },
      [
        _vm.havbg ? _c("div", { staticClass: "btn-bg" }) : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "cont" },
          [
            _vm._l(_vm.text, function(item, index) {
              return _c("div", { key: index }, [
                _c("div", { staticClass: "img" }, [
                  item.contentType == "IMAGE"
                    ? _c("img", {
                        staticClass: "tip-icon shining",
                        attrs: {
                          src: require("../../assets/img/course/icon_ring@2x.png"),
                          alt: ""
                        }
                      })
                    : _vm._e()
                ]),
                _vm._v(" "),
                item.contentType == "TEXT"
                  ? _c("span", { staticClass: "text" }, [
                      _vm._v(_vm._s(item.content))
                    ])
                  : _vm._e()
              ])
            })
          ],
          2
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }