import axios from 'axios'
import cookies from 'cookies-js'

function goToClassRoomInDragon (onlineClassId, isOpenCourse, callBackFun) {
  var dragonClientUA = navigator.userAgent.match(/vipkid\/\d+(\.\d+)*/i)
  // 灰度
  axios.get('/rest/vanguard/api/service/client/classroom/getRoomUrl', {
    params: {
      onlineClassId: onlineClassId,
      studentId: cookies.get('studentId'),
      version: dragonClientUA ? dragonClientUA[0].split('/')[1] : '0'
    }
  }).then((res) => {
    try {
      if (res.data.data.roomType === 'NA') {
        window.__bridge.openClass(onlineClassId, res.data.data.supplierCode)
        if (callBackFun) {
          callBackFun()
        }
      } else {
        flashClassRoom(isOpenCourse, callBackFun, onlineClassId)
      }
    } catch (e) {
      flashClassRoom(isOpenCourse, callBackFun, onlineClassId)
    }
  }).catch(() => {
    flashClassRoom(isOpenCourse, callBackFun, onlineClassId)
  })
}

function isOnDragonClient () {
  if (navigator.userAgent.toLowerCase().match(/vipkid/i)) {
    return true
  } else {
    return false
  }
}
function isOnIpad () {
  if (navigator.userAgent.toLowerCase().match(/ipad/i)) return true
  else return false
}

// 调起ipad bridge
function dispatchForIpad (src) {
  let _iframe = document.createElement('iframe')
  _iframe.setAttribute('src', src)
  _iframe.setAttribute('style', 'display:none;')
  document.body.appendChild(_iframe)
  _iframe.parentNode.removeChild(_iframe)
  _iframe = null
}

function flashClassRoom (isOpenCourse, callBack, onlineClassId) {
  if (isOpenCourse) {
    window.location.href = `/router/www/openclass/student/onlineclass?onlineClassId=${onlineClassId}`
  } else {
    window.location.href = `/router/learning/classroom/major?onlineClassId=${onlineClassId}`
  }
  if (callBack) {
    callBack()
  }
}

export default {
  isOnDragonClient,
  isOnIpad,
  dispatchForIpad,
  goToClassRoomInDragon
}
