import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './components/course/app.vue'
import Index from './components/course/index.vue'
import Empty from './components/course/empty.vue'
import protoMethod from './common/course/protoMethod.js'


function init () {
    window.eventBus = window.eventBus || new Vue()
    window.eventBus.$on('px-holiday-skin',  (data) =>{
        try {
            sessionStorage.setItem('skinData', JSON.stringify(data))
        } catch(e){
            console.log(e)
        }
    })
    window.eventBus.$on('px-red-tip',  (data) =>{
        try {
            sessionStorage.setItem('red-tip', data ? '1' : '0')
        } catch(e){
            console.log(e)
        }
    })
    const originalPush = VueRouter.prototype.push
    VueRouter.prototype.push = function push(location) {
        return this.replace.call(this, location).catch(err => err)
    }
    Vue.use(VueRouter)
    const routes = [
        {
            path: '/',
            title: 'VIPKID学习中心 - VIPKID在线青少儿英语',
            component: Index
        },
        {
            path: '/newmajor',
            title: 'VIPKID学习中心 - VIPKID在线青少儿英语',
            component: Index
        },
        {
            path: '*',
            component: Empty
        }
    ]
    const router = new VueRouter({
        routes,
        mode: 'history',
        saveScrollPosition: true
    })
    // 接收非px项目的路由跳转
    window.eventBus.$on('normal-route', ({to, from})=>{
        try {
            const {
                fullPath,
                path,
                query,
                hash,
                meta,
                params
            } = to
            router.push({
                fullPath,
                path,
                query,
                hash,
                meta,
                params
            })
        } catch (e) {
            console.log(e)
        }
    })
    window.VK = {
        push : function () {
            console.log('VK.push 初始化失败')
        },
        utils : {
            localStorageUtil: {
                get: function () {},
                set: function () {}
            }
        }
    }
    
    Vue.use(protoMethod)
    window.pxVm = new Vue({
        router,
        render: h => h(App)
    }).$mount("#px-app")
}

if (
    navigator.userAgent.toLowerCase().match(/apad/i) ||
    navigator.userAgent.toLowerCase().match(/ipad/i)
) {
    console.log('px 判断是native 环境，不初始化')
} else {
    init()
}