var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.cardData.cardType == "COURSE"
      ? _c("div", { staticClass: "card" }, [
          _c("div", {
            staticClass: "card-bg",
            style: {
              backgroundImage:
                _vm.skinData && _vm.skinData.courseCardTop
                  ? "url(" + _vm.skinData.courseCardTop + ")"
                  : ""
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "course-content",
              on: {
                click: function($event) {
                  return _vm.gotoDetail(
                    _vm.cardData.courseInfo.courseDetailRoute
                  )
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "course-bg",
                  style: {
                    backgroundImage: _vm.cardData.courseInfo
                      ? "url(" + _vm.cardData.courseInfo.courseTypeImg + ")"
                      : ""
                  }
                },
                [
                  _c("div", {
                    staticClass: "subject-bg",
                    style: {
                      backgroundImage: _vm.cardData.courseInfo
                        ? "url(" +
                          _vm.cardData.courseInfo.courseTypeFrontImg +
                          ")"
                        : ""
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "container" }, [
                _c("div", { staticClass: "teacher-cont" }, [
                  _c("div", { staticClass: "head-portrait" }, [
                    _vm.cardData.teacherList &&
                    _vm.cardData.teacherList[0].avatar
                      ? _c("img", {
                          attrs: {
                            src: _vm.getImg(_vm.cardData.teacherList[0].avatar),
                            alt: ""
                          }
                        })
                      : _c("img", {
                          staticClass: "default-img",
                          attrs: {
                            src: require("@/assets/img/homepage/empty.png"),
                            alt: ""
                          }
                        }),
                    _vm._v(" "),
                    _c("div", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.cardData.teacherList[0].showName))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "info-cont" },
                  [
                    _c("div", { staticClass: "title" }, [
                      _c(
                        "div",
                        {
                          staticClass: "tag",
                          style: {
                            backgroundColor:
                              _vm.cardData.courseInfo &&
                              _vm.cardData.courseInfo.courseTypeBgColor
                                ? _vm.cardData.courseInfo.courseTypeBgColor
                                : "#FE7110"
                          }
                        },
                        [
                          _c("span", { staticClass: "tag-icon" }, [
                            _vm.cardData.courseInfo &&
                            _vm.cardData.courseInfo.courseTypeIcon
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.cardData.courseInfo
                                      ? _vm.cardData.courseInfo.courseTypeIcon
                                      : "",
                                    onerror: _vm.defaultImg,
                                    alt: ""
                                  }
                                })
                              : _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/course/tag_icon_enlish@2x.png"),
                                    alt: ""
                                  }
                                })
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "tag-name" }, [
                            _vm._v(_vm._s(_vm.cardData.shortName))
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "lesson-name" }, [
                        _vm._v(_vm._s(_vm.cardData.title))
                      ]),
                      _vm._v(" "),
                      _vm.cardData.courseInfo &&
                      _vm.cardData.courseInfo.courseDetailRoute
                        ? _c("img", {
                            staticClass: "arrow-icon",
                            attrs: {
                              src: require("@/assets/img/course/arrow_primary@2x.png"),
                              alt: ""
                            }
                          })
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _vm.cardData.lessonTopic !== "" &&
                    _vm.cardData.cardType === "COURSE"
                      ? _c("div", { staticClass: "topic-name" }, [
                          _vm._v(_vm._s(_vm.cardData.lessonTopic))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "time-cont" },
                      [
                        _vm.cardData.time.type !== "TODAY"
                          ? [
                              _c(
                                "div",
                                {
                                  class: _vm.cardData.time.mutlTimes
                                    ? "segmentDisplay"
                                    : "defaultDisplay"
                                },
                                [
                                  _c("div", { staticClass: "date" }, [
                                    _vm._v(_vm._s(_vm.cardData.time.date))
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "week" }, [
                                    _vm._v(_vm._s(_vm.cardData.time.day))
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _vm.cardData.time.mutlTimes
                                ? _c(
                                    "div",
                                    _vm._l(
                                      _vm.cardData.time.mutlTimes,
                                      function(mutlTime, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "timeBox",
                                            class: mutlTime.current
                                              ? "activeTime"
                                              : ""
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "timeMark" },
                                              [_vm._v(_vm._s(mutlTime.title))]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "time" },
                                              [_vm._v(_vm._s(mutlTime.time))]
                                            )
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _c("div", { staticClass: "time" }, [
                                    _vm._v(_vm._s(_vm.cardData.time.time))
                                  ])
                            ]
                          : [
                              !_vm.cardData.time.mutlTimes
                                ? [
                                    _c("div", { staticClass: "time big" }, [
                                      _vm._v(_vm._s(_vm.cardData.time.time))
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "week" }, [
                                      _vm._v(_vm._s(_vm.cardData.time.day))
                                    ])
                                  ]
                                : [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "week segmentDisplayWeek"
                                      },
                                      [_vm._v(_vm._s(_vm.cardData.time.day))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      _vm._l(
                                        _vm.cardData.time.mutlTimes,
                                        function(mutlTime, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "timeBox",
                                              class: mutlTime.current
                                                ? "activeTime"
                                                : ""
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "timeMark" },
                                                [_vm._v(_vm._s(mutlTime.title))]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "time" },
                                                [_vm._v(_vm._s(mutlTime.time))]
                                              )
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  ]
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("vkButton", {
                      staticClass: "bottom-btn",
                      attrs: {
                        type: _vm.cardData.button.showType,
                        buttonType: _vm.cardData.button.buttonType,
                        text: _vm.cardData.button.text,
                        color: _vm.cardData.button.color,
                        havbg: true
                      },
                      on: {
                        goto: function($event) {
                          return _vm.buttonClick(
                            _vm.cardData.button,
                            _vm.cardData.onlineClass
                          )
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "center" }, [
            _vm.cardData.courseInfo
              ? _c("div", { ref: "taskcont", staticClass: "task-cont" }, [
                  _vm.cardData.courseInfo.taskInfo.tasks &&
                  _vm.cardData.courseInfo.taskInfo.tasks.length > 0
                    ? _c(
                        "ul",
                        {
                          ref: "taskline",
                          staticClass: "task-line clearfix",
                          on: {
                            mouseenter: function($event) {
                              return _vm.changeActive(
                                _vm.cardData.courseInfo.taskInfo.tasks.length
                              )
                            },
                            mouseleave: function($event) {
                              return _vm.removeActive(
                                _vm.cardData.courseInfo.taskInfo.tasks.length
                              )
                            }
                          }
                        },
                        _vm._l(_vm.cardData.courseInfo.taskInfo.tasks, function(
                          item,
                          index
                        ) {
                          return _c(
                            "li",
                            {
                              key: index,
                              staticClass: "task",
                              class: [item.status === "LOCK" ? "gray" : ""],
                              on: {
                                click: function($event) {
                                  return _vm.goToPageUrl(
                                    item,
                                    _vm.cardData.onlineClass
                                  )
                                }
                              }
                            },
                            [
                              _c("div", { staticClass: "task-box" }, [
                                _c("div", { staticClass: "task-img" }, [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.getImg(
                                        _vm.taskIconMap[item.materialCode]
                                      ),
                                      alt: "",
                                      onerror: _vm.defaultImg
                                    }
                                  })
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "name" }, [
                                  _vm._v(_vm._s(item.taskName))
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "status",
                                    class:
                                      item.status == "UNFINISH" ? "gray" : ""
                                  },
                                  [
                                    item.status == "FINISH" ||
                                    item.status == "UNFINISH"
                                      ? _c("img", {
                                          attrs: {
                                            src: require("@/assets/img/course/task_completed@2x.png"),
                                            alt: ""
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.status == "LOCK"
                                      ? _c("img", {
                                          attrs: {
                                            src: require("@/assets/img/course/task_unlock@2x.png"),
                                            alt: ""
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                ),
                                _vm._v(" "),
                                item.taskAward &&
                                item.taskAward.awardStatus !== "RECEIVE"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "award-cont",
                                        class:
                                          item.taskAward &&
                                          item.taskAward.awardStatus !==
                                            "RECEIVE" &&
                                          item.status == "UNFINISH"
                                            ? "gray"
                                            : ""
                                      },
                                      [
                                        _c("img", {
                                          class: [
                                            {
                                              shining:
                                                item.taskAward.awardStatus ==
                                                  "NOT_RECEIVE" &&
                                                item.taskAward.status ==
                                                  "COMPLETE"
                                            }
                                          ],
                                          attrs: {
                                            src: item.taskAward.awardPic,
                                            alt: ""
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "num" }, [
                                          _vm._v(
                                            _vm._s(item.taskAward.awardNumber)
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ]
                          )
                        }),
                        0
                      )
                    : _c("div", { staticClass: "null" }, [
                        _c("div", { staticClass: "img-box" }, [
                          _c("img", {
                            attrs: {
                              src: _vm.cardData.courseInfo.taskInfo.tipImg,
                              alt: "",
                              onerror: _vm.defaultImg
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "desc" }, [
                          _vm._v(
                            _vm._s(_vm.cardData.courseInfo.taskInfo.tipText)
                          )
                        ])
                      ])
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          Object.keys(_vm.allTaskAward).length > 0 &&
          _vm.cardData.courseInfo &&
          _vm.cardData.courseInfo.taskInfo.tasks.length > 0
            ? _c(
                "div",
                {
                  staticClass: "bottom",
                  class: [
                    {
                      canReceive:
                        _vm.allTaskAward.awardStatus == "NOT_RECEIVE" &&
                        _vm.allTaskAward.status == "COMPLETE"
                    },
                    { received: _vm.allTaskAward.awardStatus == "RECEIVE" }
                  ],
                  on: {
                    click: function($event) {
                      return _vm.getAllAward(_vm.allTaskAward)
                    }
                  }
                },
                [
                  _vm.allTaskAward.awardStatus !== "RECEIVE"
                    ? _c(
                        "div",
                        { staticClass: "award-icon" },
                        [
                          _vm.allTaskAward.awardPic &&
                          _vm.allTaskAward.status !== "COMPLETE"
                            ? [
                                _c("img", {
                                  staticClass: "icon",
                                  class: [
                                    {
                                      shining:
                                        _vm.allTaskAward.awardStatus ==
                                          "NOT_RECEIVE" &&
                                        _vm.allTaskAward.status == "COMPLETE"
                                    }
                                  ],
                                  attrs: {
                                    src: _vm.allTaskAward.awardPic,
                                    alt: ""
                                  }
                                })
                              ]
                            : [
                                _c("img", {
                                  staticClass: "icon",
                                  class: [
                                    {
                                      shining:
                                        _vm.allTaskAward.awardStatus ==
                                          "NOT_RECEIVE" &&
                                        _vm.allTaskAward.status == "COMPLETE"
                                    }
                                  ],
                                  attrs: {
                                    src:
                                      "https://s.vipkidstatic.com/cum/tatic/images/learning_center/homepage/revision/activity/enery_store.png",
                                    alt: ""
                                  }
                                })
                              ],
                          _vm._v(" "),
                          _vm.allTaskAward.awardStatus !== "RECEIVE" &&
                          _vm.allTaskAward.status !== "COMPLETE"
                            ? _c("span", {
                                staticClass: "bg-icon",
                                class: [
                                  {
                                    shining:
                                      _vm.allTaskAward.awardStatus ==
                                        "NOT_RECEIVE" &&
                                      _vm.allTaskAward.status == "COMPLETE"
                                  }
                                ]
                              })
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.allTaskAward.awardStatus == "RECEIVE" &&
                  _vm.allTaskAward.status == "COMPLETE"
                    ? _c("span", { staticClass: "num" }, [_vm._v("已领取")])
                    : _c("span", { staticClass: "num" }, [
                        _vm._v(_vm._s(_vm.allTaskAward.awardNumber))
                      ])
                ]
              )
            : _vm._e()
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.cardData.cardType == "OTHER"
      ? _c("div", { staticClass: "specialCard" }, [
          _c("div", {
            staticClass: "card-bg",
            style: [
              {
                backgroundImage:
                  _vm.skinData && _vm.skinData.courseSpecialCardTop
                    ? "url(" + _vm.skinData.courseSpecialCardTop + ")"
                    : ""
              }
            ]
          }),
          _vm._v(" "),
          _c("div", { staticClass: "cont" }, [
            _c("h5", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.cardData.title))
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "desc" }, [
              _vm._v(_vm._s(_vm.cardData.description))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "img-box" }, [
              _c("img", {
                attrs: {
                  src: _vm.cardData.imageUrl,
                  alt: "",
                  onerror: _vm.defaultImg
                }
              })
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "status" },
              [
                _vm.cardData.button
                  ? _c("vkButton", {
                      staticClass: "bottom-btn",
                      attrs: {
                        type: _vm.cardData.button.showType,
                        text: _vm.cardData.button.text,
                        color: _vm.cardData.button.color
                      },
                      on: {
                        goto: function($event) {
                          return _vm.buttonClick(
                            _vm.cardData.button,
                            _vm.cardData.onlineClass,
                            _vm.cardData.cardType,
                            _vm.cardData.title
                          )
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.checkPeriodicBtn(_vm.cardData)
                  ? _c("vkButton", {
                      staticClass: "bottom-btn bottom-btn-periodic",
                      attrs: {
                        type: _vm.cardData.periodicButton.showType,
                        text: _vm.cardData.periodicButton.text,
                        color: _vm.cardData.periodicButton.color
                      },
                      on: {
                        goto: function($event) {
                          return _vm.buttonClick(
                            _vm.cardData.periodicButton,
                            _vm.cardData.onlineClass,
                            _vm.cardData.cardType,
                            _vm.cardData.title
                          )
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardData.taskAward &&
                _vm.cardData.taskAward.status == "COMPLETE"
                  ? _c("img", {
                      staticClass: "complete-icon",
                      attrs: {
                        src: require("@/assets/img/course/task_completed@2x.png"),
                        alt: ""
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _vm.cardData.taskAward
              ? _c(
                  "div",
                  {
                    staticClass: "award-cont",
                    on: {
                      click: function($event) {
                        return _vm.getAllAward(
                          _vm.cardData.taskAward,
                          _vm.cardData.cardType,
                          _vm.cardData.title
                        )
                      }
                    }
                  },
                  [
                    _c("img", {
                      class: [
                        {
                          shining:
                            _vm.cardData.taskAward.awardStatus ==
                              "NOT_RECEIVE" &&
                            _vm.cardData.taskAward.status == "COMPLETE"
                        }
                      ],
                      attrs: { src: _vm.cardData.taskAward.awardPic, alt: "" }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "num" }, [
                      _vm._v(_vm._s(_vm.cardData.taskAward.awardNumber))
                    ])
                  ]
                )
              : _vm._e()
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }