var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showing,
          expression: "showing"
        }
      ],
      staticClass: "vk-dialog-adultapp"
    },
    [
      _c("div", { staticClass: "bg" }),
      _vm._v(" "),
      _c("div", { staticClass: "container" }, [
        _c("img", {
          staticClass: "close",
          attrs: { src: require("./img/close.png") },
          on: { click: _vm.hide }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "container-bg" }),
        _vm._v(" "),
        _vm._m(0)
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container-inner" }, [
      _c("img", {
        staticClass: "label",
        attrs: { src: require("./img/label.png") }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "info-panel" }, [
        _c("div", { staticClass: "part part1" }, [
          _c("div", { staticClass: "title" }, [_vm._v("方式一")]),
          _vm._v(" "),
          _c("div", { staticClass: "bottom" }, [
            _c("div", { staticClass: "info-part part1" }, [
              _c("img", { attrs: { src: require("./img/logo.png") } }),
              _vm._v(" "),
              _c("div", { staticClass: "text" }, [
                _vm._v("\n                打开"),
                _c("span", { staticClass: "highlight1" }, [
                  _vm._v("大咖英语APP")
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "info-part arrow" }, [
              _c("img", { attrs: { src: require("./img/arrow.png") } })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "info-part part2" }, [
              _c("img", { attrs: { src: require("./img/desc2.png") } }),
              _vm._v(" "),
              _c("div", { staticClass: "text" }, [
                _vm._v("\n                选择"),
                _c("span", { staticClass: "highlight1" }, [
                  _vm._v("北美外教-我的课表")
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "info-part arrow" }, [
              _c("img", { attrs: { src: require("./img/arrow.png") } })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "info-part part3" }, [
              _c("img", { attrs: { src: require("./img/desc3.png") } }),
              _vm._v(" "),
              _c("div", { staticClass: "text" }, [
                _vm._v("\n                在"),
                _c("span", { staticClass: "highlight1" }, [_vm._v("已上课程")]),
                _vm._v("开始AI随堂练\n              ")
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "part part2" }, [
          _c("div", { staticClass: "title" }, [_vm._v("方式二")]),
          _vm._v(" "),
          _c("div", { staticClass: "bottom" }, [
            _c("div", { staticClass: "left" }, [
              _c("div", { staticClass: "info" }, [
                _c("div", [
                  _vm._v("\n                  打开"),
                  _c("span", { staticClass: "highlight1" }, [
                    _vm._v("微信-扫一扫，")
                  ]),
                  _vm._v("扫描右方二维码，\n                ")
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm._v("\n                  在"),
                  _c("span", { staticClass: "highlight1" }, [
                    _vm._v("大咖英语APP")
                  ]),
                  _vm._v("里进行AI随堂练习~\n                ")
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "right" }, [
              _c("img", {
                staticClass: "qrcode",
                attrs: { src: require("./img/qrcode.png") }
              })
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "tips" }, [
          _vm._v("注：如还没有大咖英语APP，请先到手机应用市场下载安装")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }