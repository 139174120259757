var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "get-reward" },
    [
      _c("div", { staticClass: "mask" }),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: {
            "enter-active-class": "animated fadeIn",
            "leave-active-class": "animated fadeOut"
          }
        },
        [
          _c("div", { staticClass: "base-reward" }, [
            _c(
              "div",
              { staticClass: "aniAward" },
              _vm._l(_vm.stars, function(star, index) {
                return _c("img", {
                  key: index,
                  staticClass: "star-an",
                  class: _vm.isUpgrade ? "medal-an" : "",
                  style: star,
                  attrs: {
                    src: _vm.iconUrl ? _vm.iconUrl : _vm.awardIconMap[_vm.type]
                  }
                })
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showRewards,
                    expression: "showRewards"
                  }
                ],
                staticClass: "light",
                attrs: {
                  src: _vm.type ? _vm.lightMap[_vm.type] : _vm.lightMap["STAR"]
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showRewards,
                      expression: "showRewards"
                    }
                  ],
                  staticClass: "spot",
                  class: _vm.type
                },
                [
                  _vm.isUpgrade
                    ? _c("div", { staticClass: "award-title" }, [
                        _vm._v(
                          "\n            " + _vm._s(_vm.title) + "\n          "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isUpgrade
                    ? _c("div", { staticClass: "sub-title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.subTitle) +
                            "\n          "
                        )
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "circle-cont", class: _vm.type },
                _vm._l(5, function(i) {
                  return _c("div", {
                    key: i,
                    staticClass: "circle",
                    class: "circle-" + i
                  })
                }),
                0
              ),
              _vm._v(" "),
              _vm.number && _vm.number !== null && !_vm.isUpgrade
                ? _c(
                    "div",
                    { staticClass: "number" },
                    [
                      _c("number", {
                        ref: "numberText",
                        staticClass: "number-text",
                        attrs: { number: _vm.impelNumber }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showRewards,
                      expression: "showRewards"
                    }
                  ],
                  staticClass: "rewards",
                  class: _vm.isUpgrade ? "isUpgrade-rewards" : ""
                },
                [
                  _c("img", {
                    ref: "award",
                    staticClass: "award-icon",
                    class: _vm.isUpgrade ? "medal-an" : "",
                    attrs: {
                      src: _vm.iconUrl
                        ? _vm.iconUrl
                        : _vm.awardIconMap[_vm.type]
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _vm.isUpgrade && _vm.showRewards
                ? _c("div", { staticClass: "medal-icon-show" }, [
                    _c("img", {
                      staticClass: "award-icon",
                      attrs: { src: _vm.subIconUrl }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "addAward" }, [
                      _vm._v(" X" + _vm._s(_vm.number))
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.desc && _vm.showRewards
                ? _c(
                    "div",
                    {
                      staticClass: "desc-text",
                      class: _vm.isUpgrade ? "text-font" : ""
                    },
                    [_vm._v(" " + _vm._s(_vm.desc))]
                  )
                : _vm._e()
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }