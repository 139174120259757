var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.popUpsType,
    _vm._b({ tag: "component" }, "component", _vm.configData, false),
    [
      _vm._t("default", [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.configData.qrData.displayText))
          ]),
          _vm._v(" "),
          _c("img", {
            staticClass: "qr-code",
            attrs: { src: _vm.configData.qrData.qrCodeUrl }
          })
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }