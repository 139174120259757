var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "toast-con" }, [
    _c("div", { staticClass: "toast animated bounceIn" }, [
      _c("div", { staticClass: "toast-bg" }),
      _vm._v(" "),
      _c("div", { staticClass: "toast-board" }),
      _vm._v(" "),
      _c("div", {
        staticClass: "content",
        domProps: { innerHTML: _vm._s(_vm.message) }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }